/* progressssssss bar*/
#marker-info{
	display: none;
}
.show-car-list{
	cursor:pointer;
}
.ko-progress-circle {
  margin: 20px auto;
  width: 120px;
  height: 120px;
  background-color: #D9D9D9;
  border-radius: 50%;
}
.ko-progress-circle .ko-progress-circle__slice, .ko-progress-circle .ko-progress-circle__fill {
  width: 120px;
  height: 120px;
  position: absolute;
  -webkit-backface-visibility: hidden;
  transition: transform 1s;
  border-radius: 50%;
}
.ko-progress-circle .ko-progress-circle__slice {
  clip: rect(0px, 120px, 120px, 60px);
}
.ko-progress-circle .ko-progress-circle__slice .ko-progress-circle__fill {
  clip: rect(0px, 60px, 120px, 0px);
  background-color: #1291D4;
}
.ko-progress-circle .ko-progress-circle__overlay {
  width: 105px;
  height: 105px;
  position: absolute;
  margin-left: 7.5px;
  margin-top: 7.5px;
  background-color: #fbfbfb;
  border-radius: 50%;
}
.ko-progress-circle[data-progress='0'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='0'] .ko-progress-circle__fill {
  transform: rotate(0deg);
}
.ko-progress-circle[data-progress='0'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(0deg);
}
.ko-progress-circle[data-progress='1'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='1'] .ko-progress-circle__fill {
  transform: rotate(1.8deg);
}
.ko-progress-circle[data-progress='1'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(3.6deg);
}
.ko-progress-circle[data-progress='2'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='2'] .ko-progress-circle__fill {
  transform: rotate(3.6deg);
}
.ko-progress-circle[data-progress='2'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(7.2deg);
}
.ko-progress-circle[data-progress='3'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='3'] .ko-progress-circle__fill {
  transform: rotate(5.4deg);
}
.ko-progress-circle[data-progress='3'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(10.8deg);
}
.ko-progress-circle[data-progress='4'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='4'] .ko-progress-circle__fill {
  transform: rotate(7.2deg);
}
.ko-progress-circle[data-progress='4'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(14.4deg);
}
.ko-progress-circle[data-progress='5'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='5'] .ko-progress-circle__fill {
  transform: rotate(9deg);
}
.ko-progress-circle[data-progress='5'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(18deg);
}
.ko-progress-circle[data-progress='6'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='6'] .ko-progress-circle__fill {
  transform: rotate(10.8deg);
}
.ko-progress-circle[data-progress='6'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(21.6deg);
}
.ko-progress-circle[data-progress='7'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='7'] .ko-progress-circle__fill {
  transform: rotate(12.6deg);
}
.ko-progress-circle[data-progress='7'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(25.2deg);
}
.ko-progress-circle[data-progress='8'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='8'] .ko-progress-circle__fill {
  transform: rotate(14.4deg);
}
.ko-progress-circle[data-progress='8'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(28.8deg);
}
.ko-progress-circle[data-progress='9'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='9'] .ko-progress-circle__fill {
  transform: rotate(16.2deg);
}
.ko-progress-circle[data-progress='9'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(32.4deg);
}
.ko-progress-circle[data-progress='10'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='10'] .ko-progress-circle__fill {
  transform: rotate(18deg);
}
.ko-progress-circle[data-progress='10'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(36deg);
}
.ko-progress-circle[data-progress='11'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='11'] .ko-progress-circle__fill {
  transform: rotate(19.8deg);
}
.ko-progress-circle[data-progress='11'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(39.6deg);
}
.ko-progress-circle[data-progress='12'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='12'] .ko-progress-circle__fill {
  transform: rotate(21.6deg);
}
.ko-progress-circle[data-progress='12'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(43.2deg);
}
.ko-progress-circle[data-progress='13'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='13'] .ko-progress-circle__fill {
  transform: rotate(23.4deg);
}
.ko-progress-circle[data-progress='13'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(46.8deg);
}
.ko-progress-circle[data-progress='14'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='14'] .ko-progress-circle__fill {
  transform: rotate(25.2deg);
}
.ko-progress-circle[data-progress='14'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(50.4deg);
}
.ko-progress-circle[data-progress='15'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='15'] .ko-progress-circle__fill {
  transform: rotate(27deg);
}
.ko-progress-circle[data-progress='15'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(54deg);
}
.ko-progress-circle[data-progress='16'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='16'] .ko-progress-circle__fill {
  transform: rotate(28.8deg);
}
.ko-progress-circle[data-progress='16'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(57.6deg);
}
.ko-progress-circle[data-progress='17'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='17'] .ko-progress-circle__fill {
  transform: rotate(30.6deg);
}
.ko-progress-circle[data-progress='17'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(61.2deg);
}
.ko-progress-circle[data-progress='18'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='18'] .ko-progress-circle__fill {
  transform: rotate(32.4deg);
}
.ko-progress-circle[data-progress='18'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(64.8deg);
}
.ko-progress-circle[data-progress='19'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='19'] .ko-progress-circle__fill {
  transform: rotate(34.2deg);
}
.ko-progress-circle[data-progress='19'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(68.4deg);
}
.ko-progress-circle[data-progress='20'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='20'] .ko-progress-circle__fill {
  transform: rotate(36deg);
}
.ko-progress-circle[data-progress='20'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(72deg);
}
.ko-progress-circle[data-progress='21'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='21'] .ko-progress-circle__fill {
  transform: rotate(37.8deg);
}
.ko-progress-circle[data-progress='21'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(75.6deg);
}
.ko-progress-circle[data-progress='22'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='22'] .ko-progress-circle__fill {
  transform: rotate(39.6deg);
}
.ko-progress-circle[data-progress='22'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(79.2deg);
}
.ko-progress-circle[data-progress='23'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='23'] .ko-progress-circle__fill {
  transform: rotate(41.4deg);
}
.ko-progress-circle[data-progress='23'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(82.8deg);
}
.ko-progress-circle[data-progress='24'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='24'] .ko-progress-circle__fill {
  transform: rotate(43.2deg);
}
.ko-progress-circle[data-progress='24'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(86.4deg);
}
.ko-progress-circle[data-progress='25'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='25'] .ko-progress-circle__fill {
  transform: rotate(45deg);
}
.ko-progress-circle[data-progress='25'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(90deg);
}
.ko-progress-circle[data-progress='26'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='26'] .ko-progress-circle__fill {
  transform: rotate(46.8deg);
}
.ko-progress-circle[data-progress='26'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(93.6deg);
}
.ko-progress-circle[data-progress='27'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='27'] .ko-progress-circle__fill {
  transform: rotate(48.6deg);
}
.ko-progress-circle[data-progress='27'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(97.2deg);
}
.ko-progress-circle[data-progress='28'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='28'] .ko-progress-circle__fill {
  transform: rotate(50.4deg);
}
.ko-progress-circle[data-progress='28'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(100.8deg);
}
.ko-progress-circle[data-progress='29'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='29'] .ko-progress-circle__fill {
  transform: rotate(52.2deg);
}
.ko-progress-circle[data-progress='29'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(104.4deg);
}
.ko-progress-circle[data-progress='30'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='30'] .ko-progress-circle__fill {
  transform: rotate(54deg);
}
.ko-progress-circle[data-progress='30'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(108deg);
}
.ko-progress-circle[data-progress='31'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='31'] .ko-progress-circle__fill {
  transform: rotate(55.8deg);
}
.ko-progress-circle[data-progress='31'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(111.6deg);
}
.ko-progress-circle[data-progress='32'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='32'] .ko-progress-circle__fill {
  transform: rotate(57.6deg);
}
.ko-progress-circle[data-progress='32'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(115.2deg);
}
.ko-progress-circle[data-progress='33'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='33'] .ko-progress-circle__fill {
  transform: rotate(59.4deg);
}
.ko-progress-circle[data-progress='33'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(118.8deg);
}
.ko-progress-circle[data-progress='34'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='34'] .ko-progress-circle__fill {
  transform: rotate(61.2deg);
}
.ko-progress-circle[data-progress='34'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(122.4deg);
}
.ko-progress-circle[data-progress='35'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='35'] .ko-progress-circle__fill {
  transform: rotate(63deg);
}
.ko-progress-circle[data-progress='35'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(126deg);
}
.ko-progress-circle[data-progress='36'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='36'] .ko-progress-circle__fill {
  transform: rotate(64.8deg);
}
.ko-progress-circle[data-progress='36'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(129.6deg);
}
.ko-progress-circle[data-progress='37'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='37'] .ko-progress-circle__fill {
  transform: rotate(66.6deg);
}
.ko-progress-circle[data-progress='37'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(133.2deg);
}
.ko-progress-circle[data-progress='38'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='38'] .ko-progress-circle__fill {
  transform: rotate(68.4deg);
}
.ko-progress-circle[data-progress='38'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(136.8deg);
}
.ko-progress-circle[data-progress='39'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='39'] .ko-progress-circle__fill {
  transform: rotate(70.2deg);
}
.ko-progress-circle[data-progress='39'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(140.4deg);
}
.ko-progress-circle[data-progress='40'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='40'] .ko-progress-circle__fill {
  transform: rotate(72deg);
}
.ko-progress-circle[data-progress='40'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(144deg);
}
.ko-progress-circle[data-progress='41'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='41'] .ko-progress-circle__fill {
  transform: rotate(73.8deg);
}
.ko-progress-circle[data-progress='41'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(147.6deg);
}
.ko-progress-circle[data-progress='42'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='42'] .ko-progress-circle__fill {
  transform: rotate(75.6deg);
}
.ko-progress-circle[data-progress='42'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(151.2deg);
}
.ko-progress-circle[data-progress='43'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='43'] .ko-progress-circle__fill {
  transform: rotate(77.4deg);
}
.ko-progress-circle[data-progress='43'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(154.8deg);
}
.ko-progress-circle[data-progress='44'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='44'] .ko-progress-circle__fill {
  transform: rotate(79.2deg);
}
.ko-progress-circle[data-progress='44'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(158.4deg);
}
.ko-progress-circle[data-progress='45'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='45'] .ko-progress-circle__fill {
  transform: rotate(81deg);
}
.ko-progress-circle[data-progress='45'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(162deg);
}
.ko-progress-circle[data-progress='46'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='46'] .ko-progress-circle__fill {
  transform: rotate(82.8deg);
}
.ko-progress-circle[data-progress='46'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(165.6deg);
}
.ko-progress-circle[data-progress='47'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='47'] .ko-progress-circle__fill {
  transform: rotate(84.6deg);
}
.ko-progress-circle[data-progress='47'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(169.2deg);
}
.ko-progress-circle[data-progress='48'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='48'] .ko-progress-circle__fill {
  transform: rotate(86.4deg);
}
.ko-progress-circle[data-progress='48'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(172.8deg);
}
.ko-progress-circle[data-progress='49'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='49'] .ko-progress-circle__fill {
  transform: rotate(88.2deg);
}
.ko-progress-circle[data-progress='49'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(176.4deg);
}
.ko-progress-circle[data-progress='50'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='50'] .ko-progress-circle__fill {
  transform: rotate(90deg);
}
.ko-progress-circle[data-progress='50'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(180deg);
}
.ko-progress-circle[data-progress='51'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='51'] .ko-progress-circle__fill {
  transform: rotate(91.8deg);
}
.ko-progress-circle[data-progress='51'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(183.6deg);
}
.ko-progress-circle[data-progress='52'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='52'] .ko-progress-circle__fill {
  transform: rotate(93.6deg);
}
.ko-progress-circle[data-progress='52'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(187.2deg);
}
.ko-progress-circle[data-progress='53'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='53'] .ko-progress-circle__fill {
  transform: rotate(95.4deg);
}
.ko-progress-circle[data-progress='53'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(190.8deg);
}
.ko-progress-circle[data-progress='54'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='54'] .ko-progress-circle__fill {
  transform: rotate(97.2deg);
}
.ko-progress-circle[data-progress='54'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(194.4deg);
}
.ko-progress-circle[data-progress='55'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='55'] .ko-progress-circle__fill {
  transform: rotate(99deg);
}
.ko-progress-circle[data-progress='55'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(198deg);
}
.ko-progress-circle[data-progress='56'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='56'] .ko-progress-circle__fill {
  transform: rotate(100.8deg);
}
.ko-progress-circle[data-progress='56'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(201.6deg);
}
.ko-progress-circle[data-progress='57'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='57'] .ko-progress-circle__fill {
  transform: rotate(102.6deg);
}
.ko-progress-circle[data-progress='57'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(205.2deg);
}
.ko-progress-circle[data-progress='58'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='58'] .ko-progress-circle__fill {
  transform: rotate(104.4deg);
}
.ko-progress-circle[data-progress='58'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(208.8deg);
}
.ko-progress-circle[data-progress='59'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='59'] .ko-progress-circle__fill {
  transform: rotate(106.2deg);
}
.ko-progress-circle[data-progress='59'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(212.4deg);
}
.ko-progress-circle[data-progress='60'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='60'] .ko-progress-circle__fill {
  transform: rotate(108deg);
}
.ko-progress-circle[data-progress='60'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(216deg);
}
.ko-progress-circle[data-progress='61'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='61'] .ko-progress-circle__fill {
  transform: rotate(109.8deg);
}
.ko-progress-circle[data-progress='61'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(219.6deg);
}
.ko-progress-circle[data-progress='62'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='62'] .ko-progress-circle__fill {
  transform: rotate(111.6deg);
}
.ko-progress-circle[data-progress='62'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(223.2deg);
}
.ko-progress-circle[data-progress='63'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='63'] .ko-progress-circle__fill {
  transform: rotate(113.4deg);
}
.ko-progress-circle[data-progress='63'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(226.8deg);
}
.ko-progress-circle[data-progress='64'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='64'] .ko-progress-circle__fill {
  transform: rotate(115.2deg);
}
.ko-progress-circle[data-progress='64'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(230.4deg);
}
.ko-progress-circle[data-progress='65'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='65'] .ko-progress-circle__fill {
  transform: rotate(117deg);
}
.ko-progress-circle[data-progress='65'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(234deg);
}
.ko-progress-circle[data-progress='66'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='66'] .ko-progress-circle__fill {
  transform: rotate(118.8deg);
}
.ko-progress-circle[data-progress='66'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(237.6deg);
}
.ko-progress-circle[data-progress='67'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='67'] .ko-progress-circle__fill {
  transform: rotate(120.6deg);
}
.ko-progress-circle[data-progress='67'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(241.2deg);
}
.ko-progress-circle[data-progress='68'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='68'] .ko-progress-circle__fill {
  transform: rotate(122.4deg);
}
.ko-progress-circle[data-progress='68'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(244.8deg);
}
.ko-progress-circle[data-progress='69'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='69'] .ko-progress-circle__fill {
  transform: rotate(124.2deg);
}
.ko-progress-circle[data-progress='69'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(248.4deg);
}
.ko-progress-circle[data-progress='70'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='70'] .ko-progress-circle__fill {
  transform: rotate(126deg);
}
.ko-progress-circle[data-progress='70'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(252deg);
}
.ko-progress-circle[data-progress='71'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='71'] .ko-progress-circle__fill {
  transform: rotate(127.8deg);
}
.ko-progress-circle[data-progress='71'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(255.6deg);
}
.ko-progress-circle[data-progress='72'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='72'] .ko-progress-circle__fill {
  transform: rotate(129.6deg);
}
.ko-progress-circle[data-progress='72'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(259.2deg);
}
.ko-progress-circle[data-progress='73'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='73'] .ko-progress-circle__fill {
  transform: rotate(131.4deg);
}
.ko-progress-circle[data-progress='73'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(262.8deg);
}
.ko-progress-circle[data-progress='74'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='74'] .ko-progress-circle__fill {
  transform: rotate(133.2deg);
}
.ko-progress-circle[data-progress='74'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(266.4deg);
}
.ko-progress-circle[data-progress='75'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='75'] .ko-progress-circle__fill {
  transform: rotate(135deg);
}
.ko-progress-circle[data-progress='75'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(270deg);
}
.ko-progress-circle[data-progress='76'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='76'] .ko-progress-circle__fill {
  transform: rotate(136.8deg);
}
.ko-progress-circle[data-progress='76'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(273.6deg);
}
.ko-progress-circle[data-progress='77'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='77'] .ko-progress-circle__fill {
  transform: rotate(138.6deg);
}
.ko-progress-circle[data-progress='77'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(277.2deg);
}
.ko-progress-circle[data-progress='78'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='78'] .ko-progress-circle__fill {
  transform: rotate(140.4deg);
}
.ko-progress-circle[data-progress='78'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(280.8deg);
}
.ko-progress-circle[data-progress='79'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='79'] .ko-progress-circle__fill {
  transform: rotate(142.2deg);
}
.ko-progress-circle[data-progress='79'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(284.4deg);
}
.ko-progress-circle[data-progress='80'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='80'] .ko-progress-circle__fill {
  transform: rotate(144deg);
}
.ko-progress-circle[data-progress='80'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(288deg);
}
.ko-progress-circle[data-progress='81'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='81'] .ko-progress-circle__fill {
  transform: rotate(145.8deg);
}
.ko-progress-circle[data-progress='81'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(291.6deg);
}
.ko-progress-circle[data-progress='82'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='82'] .ko-progress-circle__fill {
  transform: rotate(147.6deg);
}
.ko-progress-circle[data-progress='82'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(295.2deg);
}
.ko-progress-circle[data-progress='83'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='83'] .ko-progress-circle__fill {
  transform: rotate(149.4deg);
}
.ko-progress-circle[data-progress='83'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(298.8deg);
}
.ko-progress-circle[data-progress='84'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='84'] .ko-progress-circle__fill {
  transform: rotate(151.2deg);
}
.ko-progress-circle[data-progress='84'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(302.4deg);
}
.ko-progress-circle[data-progress='85'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='85'] .ko-progress-circle__fill {
  transform: rotate(153deg);
}
.ko-progress-circle[data-progress='85'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(306deg);
}
.ko-progress-circle[data-progress='86'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='86'] .ko-progress-circle__fill {
  transform: rotate(154.8deg);
}
.ko-progress-circle[data-progress='86'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(309.6deg);
}
.ko-progress-circle[data-progress='87'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='87'] .ko-progress-circle__fill {
  transform: rotate(156.6deg);
}
.ko-progress-circle[data-progress='87'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(313.2deg);
}
.ko-progress-circle[data-progress='88'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='88'] .ko-progress-circle__fill {
  transform: rotate(158.4deg);
}
.ko-progress-circle[data-progress='88'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(316.8deg);
}
.ko-progress-circle[data-progress='89'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='89'] .ko-progress-circle__fill {
  transform: rotate(160.2deg);
}
.ko-progress-circle[data-progress='89'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(320.4deg);
}
.ko-progress-circle[data-progress='90'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='90'] .ko-progress-circle__fill {
  transform: rotate(162deg);
}
.ko-progress-circle[data-progress='90'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(324deg);
}
.ko-progress-circle[data-progress='91'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='91'] .ko-progress-circle__fill {
  transform: rotate(163.8deg);
}
.ko-progress-circle[data-progress='91'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(327.6deg);
}
.ko-progress-circle[data-progress='92'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='92'] .ko-progress-circle__fill {
  transform: rotate(165.6deg);
}
.ko-progress-circle[data-progress='92'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(331.2deg);
}
.ko-progress-circle[data-progress='93'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='93'] .ko-progress-circle__fill {
  transform: rotate(167.4deg);
}
.ko-progress-circle[data-progress='93'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(334.8deg);
}
.ko-progress-circle[data-progress='94'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='94'] .ko-progress-circle__fill {
  transform: rotate(169.2deg);
}
.ko-progress-circle[data-progress='94'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(338.4deg);
}
.ko-progress-circle[data-progress='95'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='95'] .ko-progress-circle__fill {
  transform: rotate(171deg);
}
.ko-progress-circle[data-progress='95'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(342deg);
}
.ko-progress-circle[data-progress='96'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='96'] .ko-progress-circle__fill {
  transform: rotate(172.8deg);
}
.ko-progress-circle[data-progress='96'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(345.6deg);
}
.ko-progress-circle[data-progress='97'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='97'] .ko-progress-circle__fill {
  transform: rotate(174.6deg);
}
.ko-progress-circle[data-progress='97'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(349.2deg);
}
.ko-progress-circle[data-progress='98'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='98'] .ko-progress-circle__fill {
  transform: rotate(176.4deg);
}
.ko-progress-circle[data-progress='98'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(352.8deg);
}
.ko-progress-circle[data-progress='99'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='99'] .ko-progress-circle__fill {
  transform: rotate(178.2deg);
}
.ko-progress-circle[data-progress='99'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(356.4deg);
}
.ko-progress-circle[data-progress='100'] .ko-progress-circle__slice.full, .ko-progress-circle[data-progress='100'] .ko-progress-circle__fill {
  transform: rotate(180deg);
}
.ko-progress-circle[data-progress='100'] .ko-progress-circle__fill.ko-progress-circle__bar {
  transform: rotate(360deg);
}


/* enddddddddd*/

.dpinfo {
    postion:absolute;
    box-shadow: 10px #333;



    block:inline;

    width:200px;
    float: left;
    margin: 5px;
    text-align: center;

   vertical-align:middle;
    text-decoration:none;

}

/*radial progress bar graph*/
.circular-progress-bar {
  position: relative;
  margin: 0 auto;
  transform: rotate(-90deg);
  background-color: #1291D4;
    border-radius: 50%;
  }


.progress-percentage, .progress-text {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  text-align: center;


  padding: 0px 60px;
}

.progress-percentage {
  font-size: 60px;
  left: 60%;
}

.progress-text {
  left: 35%;
  color: #585858;
  font-size: 21px;
}




/*end radial progress bar graph*/
div.well {
    overflow-x: auto;
    white-space: nowrap;
}
div.well [class*="col"]{  /* TWBS v2 */
    display: inline-block;
    float: none; /* Very important */
}

.report_scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.report_scroll::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

.report_scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #F0772B;
}

.custom-scroll:hover::-webkit-scrollbar {
    background: #C1C1C1;
}

body::-webkit-scrollbar-thumb:hover {
    -webkit-animation: scrollbig 1s;
}

.custom-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.custom-scroll::-webkit-scrollbar
{
	width: 5px;
	height:6px;
	background-color: #C1C1C1;
}

.custom-scroll::-webkit-scrollbar-thumb
{
	border-radius: 6px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C1C1C1;
}


#loader1{
 width:100%;
 height:100%;
 position:fixed;
  /*z-index:9999;*/
  top:62px;
 visibility: visible;
  background:url("/static/images/ajax-loader.gif")  no-repeat center center rgba(0,0,0,0)
                }
#loader2{
 width:100%;
 height:100%;
 position:fixed;
  /*z-index:9999;*/
  top:62px;
 visibility: visible;
  background:url("/static/images/ajax-loader.gif")  no-repeat center center rgba(0,0,0,0)
                }

.file-upload {
    display: none;
}
.driver-file-upload{
  display: none;
}
#vla-vehicle-list{
	box-shadow: 0px 2px 6px 3px #e0e0e0!important;
        position: absolute!important;
    width: 79%!important;
    background-color: #ffffff!important;
    z-index: 9!important;
    padding-top: .5em!important;
    overflow-y:auto;
    overflow-x:hidden;
    max-height:15em;
    cursor: pointer;
    padding-left: 2em;
}
#report1-car-list-1{
  box-shadow: 0px 2px 6px 3px #e0e0e0!important;
        position: absolute!important;
    width: 98%!important;
    background-color: #ffffff!important;
    z-index: 9!important;
    padding-top: .5em!important;
    padding-bottom: 1em!important;
    overflow-y:scroll! important;
    overflow-x:hidden! important;
    height:18em;
    cursor: pointer;
}
#special-report-car-list-1{
  box-shadow: 0px 2px 6px 3px #e0e0e0;
  position: absolute;
    width: 88%;
    background-color: #ffffff;
    z-index: 9;
    padding-top: .5em;
    padding-bottom: 1em;
    overflow-y:scroll;
    overflow-x:hidden;
    height:18em;
    cursor: pointer;
}
#report-car-list-1{
  	box-shadow: 0px 2px 6px 3px #b5b5b5;
    position: absolute!important;
    background-color: #ffffff!important;
    z-index: 9!important;
    width: 90% !important;
    padding-top: .5em!important;
    overflow-y:auto! important;
    overflow-x:hidden! important;
    max-height:18em;
    padding-right: .4em;
    cursor: pointer;
}
#driver-report-car-list-1{
  box-shadow: 0px 2px 6px 3px #e0e0e0!important;
        position: absolute!important;
    width: 93%!important;
    background-color: #ffffff!important;
    z-index: 9!important;
    padding-top: .5em!important;
    overflow-y:auto! important;
    overflow-x:hidden! important;
    max-height:18em;
    cursor: pointer;
}
.driver-report-car-name,.driver-multi-car-name{
	border-bottom: 1px solid #cccccc;
}
#driver-multi-car-list-1{
  box-shadow: 0px 2px 6px 3px #e0e0e0!important;
        position: absolute!important;
    width: 93%!important;
    background-color: #ffffff!important;
    z-index: 9!important;
    padding-top: .5em!important;
    overflow-y:auto! important;
    overflow-x:hidden! important;
    max-height:18em;
    cursor: pointer;
}
#driver-car-list-1{
    box-shadow: 0px 2px 6px 3px #6b6b6b;
    position: absolute!important;
    width: 90%!important;
    background-color: #ffffff!important;
    z-index: 9!important;
    padding-top: .5em!important;
    overflow-y:auto! important;
    overflow-x:hidden! important;
    max-height:18em;
    cursor: pointer;
}
.driver-car-name{
	border-bottom: 1px solid #cccccc;
}

.destination-delete-edit{
  cursor: pointer;
}
#location_alarm_hrs_list{
	box-shadow: 0px 2px 6px 3px #e0e0e0!important;
        position: absolute!important;
    width: 88%!important;
    background-color: #ffffff!important;
    z-index: 9!important;
    padding-top: .5em!important;
    padding-bottom: 1em!important;
    overflow-y:scroll;
    overflow-x:hidden;
    height:15em;
    cursor: pointer;
}
#time_type_list{
  box-shadow: 0px 2px 6px 3px #e0e0e0!important;
        position: absolute!important;
    width: 88%!important;
    background-color: #ffffff!important;
    z-index: 9!important;
    padding-top: .5em!important;
    padding-bottom: 1em!important;

    overflow-x:hidden;

    cursor: pointer;
}
#time-location-alarm-minutes-list{
	box-shadow: 0px 2px 6px 3px #e0e0e0!important;
        position: absolute!important;
    width: 88%!important;
    background-color: #ffffff!important;
    z-index: 9!important;
    padding-top: .5em!important;
    padding-bottom: 1em!important;
    overflow-y:scroll;
    overflow-x:hidden;
    height:15em;
    cursor: pointer;
}
#time_location_alarm_hrs_list{
	box-shadow: 0px 2px 6px 3px #e0e0e0!important;
        position: absolute!important;
    width: 88%!important;
    background-color: #ffffff!important;
    z-index: 9!important;
    padding-top: .5em!important;
    padding-bottom: 1em!important;
    overflow-y:scroll;
    overflow-x:hidden;
    height:15em;
    cursor: pointer;
}
#location-alarm-minutes-list{
	box-shadow: 0px 2px 6px 3px #e0e0e0!important;
        position: absolute!important;
    width: 88%!important;
    background-color: #ffffff!important;
    z-index: 9!important;
    padding-top: .5em!important;
    padding-bottom: 1em!important;
    overflow-y:scroll;
    overflow-x:hidden;
    height:15em;
    cursor: pointer;
}
.new-bound-save{
	padding: 10px -50px;
    font-size: 15px;
    border-radius: 10px;

}
#loader{
	position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  background-size:50%;
}
#child-account-car-list{
	box-shadow: 0px 2px 6px 3px #e0e0e0!important;
        position: absolute!important;
    width: 88%!important;
    background-color: #ffffff!important;
    z-index: 9!important;
    padding-top: .5em!important;
    padding-bottom: 1em!important;
    overflow-y:scroll;
    overflow-x:hidden;
    height:15em;
    cursor: pointer;
}
.container-fluid{
        height:100%;
        width:100%;
}
/* html{
	overflow: hidden;
} */
.panel{
        box-shadow:0px 1px 14px 0px gray;
        border-radius:0px;
        margin-bottom:0px;

}
.mpgps-container{
        height:50%;
}
.container-fluid,.mpgps-container{
        padding-left:0px!important;
        padding-right:0px!important;
}
.navbar-inverse{
        border-radius:0px;
        background-color:#5D6066;
        border-color:#5D6066;
        display:inline-block;
}
.mpgps-nav-button1{
        width:50px;
        height:50px;
        border-radius:50%;
        margin-top:20%;
        border:solid 2px #DCDBDD;
        display:table-cell;
        vertical-align:middle;
        text-align:center;
        cursor:pointer;
}
.mpgps-nav-button2{
        width:50px;
        height:50px;
        border-radius:50%;
        margin-top:20%;
        border:solid 2px #DCDBDD;
        display:table-cell;
        vertical-align:middle;
        text-align:center;
        cursor:pointer;
}
.mpgps-nav-button3{
        width:50px;
        height:50px;
        border-radius:50%;
        margin-top:20%;
        border:solid 2px #DCDBDD;
        display:table-cell;
        vertical-align:middle;
        text-align:center;
        cursor:pointer;
}
.mpgps-nav-button4{
        width:50px;
        height:50px;
        border-radius:50%;
        margin-top:20%;
        border:solid 2px #DCDBDD;
        display:table-cell;
        vertical-align:middle;
        text-align:center;
        cursor:pointer;
}
.mpgps-nav-button5{
        width:50px;
        height:50px;
        border-radius:50%;
        margin-top:20%;
        border:solid 2px #DCDBDD;
        display:table-cell;
        vertical-align:middle;
        text-align:center;
        cursor:pointer;
}
.mpgps-nav-button6{
        width:50px;
        height:50px;
        border-radius:50%;
        margin-top:20%;
        border:solid 2px #DCDBDD;
        display:table-cell;
        vertical-align:middle;
        text-align:center;
        cursor:pointer;
}
.mpgps-nav-button7{
        width:50px;
        height:50px;
        border-radius:50%;
        margin-top:20%;
        border:solid 2px #DCDBDD;
        display:table-cell;
        vertical-align:middle;
        text-align:center;
        cursor:pointer;
}

.mpgps-nav-button img{
        height:30px;
        width:30px;
}
.navbar .navbar-text li{
        margin-top:30%;
}
.glyphicon .glyphicon-user .white{
        color:white;
}
.pointer{
        cursor:pointer;
}
.container-fluid{
        height: 100%;
        }
nav.navbar.navbar-inverse.col-sm-1{
	height: inherit;
	position: fixed;
        z-index: 1;
	left: 0px;
	/*width: initial;*/
	width: 5.6%;
	top: 87px;
	background-color:#696969
        }
#custom-nav {
   -webkit-box-shadow: 12px -1px 35px #82807b;
   -moz-box-shadow:    12px -1px 35px #82807b;
    box-shadow:         12px -1px 35px #82807b;
    z-index:999;
}
#side {
        display: block;
}

.panel {
	position:fixed;
	z-index: 1;
	width: inherit;
}
.center-block {
    display: block;
    margin-left: 32vw;
    margin-right: 50%;
 }
label.paras.helper {
    margin-left: 25vw;
 }

#forgetpassword {
	display: none;
}

/*#welcometab {
	position:fixed;
	left:150px;
	height: 32vh;
	width: 46vh;
	margin-top: 40vh;
	background-color: #FFFFFF;
}*/
#home.active{
    background-color : blue
}
.backspace { margin-left: 150px; }
#notificationmodal {
	display:none;
	}
span.glyphicon.glyphicon-bell {
    top: 8px;
}
#successdialogpage {
	display:none;
}

#image {
   content:url('/static/images/Bell-Orange.png');
   padding:15px 1px 1px 27px;
}

#image1 {
   content:url('/static/images/Ongoing.png');
   height:23px;
}
#image2 {
   content:url('/static/images/Parked.png');
   height:23px;
}
#image3 {
   content:url('/static/images/Idle.png');
   height:23px;
}
#image4 {
   content:url('/static/images/Cluster-Marker.png');
   height:23px;
}
#image5 {
   content:url('/static/images/Connection-Lost-Marker.png');
   height:23px;
}

.img-frame {
    background:#4A6FA3;
    border:1px solid #999999;
    width:6vw;
    height:6vh;
    margin-top: 23vh;
    margin-left: 37vw;
    display:inherit;}

#myModell {
	display:none;
}
.first-column {
  width: 85%;
  float: left;
  margin-top: -5vh;
}
.second-column {
  width: 40%;
  float: right;
}
/*LIVE TRACK AND REPLAY ROUTE VEHICLE VIEWS*/
#track{
	display:none;
}
.mpgps-track-container{
	position:absolute;
	top:30%;
	left:8%;
	width:500px!important;
	height:50%
}
.mpgps-track-welcome-container{
	position:absolute;
	top:50%;
	left:8%;
        width: 30%;
    	height: 32%;
	box-shadow: 3px 3px 30px grey, 0 0 5px black;
	background:#ffffff;
}
.mpgps-track-static-details-container{
	position:absolute;
	top:50%;
	left:8%;
        width: 30%;
    	height: 32%;
	box-shadow: 3px 3px 30px grey, 0 0 5px black;
	background:#ffffff;
}
.mpgps-button{
	font-family:helvetica;
	font-weight:bold;
	padding-left:25px;
	padding-right:25px;
	padding-top:15px;
	padding-bottom:15px;
	pointer:cursor;
	text-align:center!important;
	cursor:pointer;
	color:#9C9FA2;
	background-color:#F8F8F8;
	border:solid 1px #9C9FA2;
}
.mpgps-selected{
	color:#FCFCFC;
	background-color:#333537;
	box-shadow:0px -5px 0px 0px #F0762B inset;
}
.legend-div{
	width: 24%;
}
.vh-data-div-1{
	width: 19.4%;
}
.vh-data-div-2{
	width: 19.5%;
}
.mpgps-vehicle-holder{
	width:70%;
	margin-top:52px;
	/* background-color:white; */
	background-color:transparent;
	box-shadow: 3px 3px 30px grey, 0 0 5px black;
}
.mpgps-vehicle-holder1{
	width:130%;
	/* background-color:white; */
	background-color:#ffffff;
	box-shadow: 3px 3px 30px grey, 0 0 5px black;
	margin-top:52px;
	/*height:auto;*/
}
.mpgps-vehicle-holder span{
 padding:1px 1px 1px 24px;
}
.mpgps-track-table td{
	border-right:solid 1px #E0E0E0;
	border-bottom:solid 1px #E0E0E0;
	text-align:center;
	height:60px;
	background-color:white;
	font-family:helvetica;
}
.mpgps-track-search{
	height: 45px;
	width:100%;
	background:white;
	border:solid 1px #E0E0E0;
	font-family:helvetica;
	font-size:20px;
	padding: 26px 35px 25px 57px;
}
.mpgps-track-div{
	height: 56px;
	width:100%;
	background:white;
	border:solid 1px #E0E0E0;
	font-family:helvetica;
	font-size:20px;
	padding: 11px 20px 20px 24px;/*61px 15px 25px 27px;*/
	/*padding:5px;*/
}
input:focus{
	outline-width: 0;
	padding-left:5px;
}
/*LIVE TRACK AND REPLAY ROUTE VEHICLE VIEWS*/
#bound {
	display:none;
}
#set_boundaries {
	display:none;
}
/* ------------------------for boundary setting */
* {box-sizing: border-box}
body {font-family: "Lato", sans-serif;}

/* Style the tab */
div.tab {
    float: left;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
    width: 30%;
    height: 300px;
}

/* Style the buttons inside the tab */
div.tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 22px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 17px;
}

/* Change background color of buttons on hover */
div.tab button:hover {
    background-color: #ddd;
}

/* Create an active/current "tab button" class */
div.tab button.active {
    background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
    float: left;
    padding: 0px 50px;
    width: 70%;
    border-left: none;
    height: 300px;
}
#Layer4 {
	position:absolute;
	left:145px;
	top:272px;
	width:237px;
	height:271px;
	z-index:4;
}
#Layer5 {
	position:absolute;
	left:390px;
	top:137px;
	width:833px;
	height:269px;
	z-index:5;
}
#Layer3 {
	position:absolute;
	left:143px;
	top:89px;
	width:100%;
	height:318px;
	z-index:3;
}
#map {
        width: 100%;
       }
#current{
     padding-top: 25px;
}
#boundary_locate{
	display:none;
}
#demo{
	border: none;
border-color: transparent;
}
.odometer-inside{
	font-size: 19px !important;
}
#boundary_save{
	display: none;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {display:none;}

#boundary_frame,#speed_frame,#destination_frame,#user_frame,#vehicle_frame,#setting_frame{
  cursor: pointer;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.right-box {
    box-shadow: outset -7px 0 9px -7px rgba(0,0,0,0.7);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
#div1{
		    font-size:30px;
		    padding: 13px 0px 0px 5px;
}
#div2{
	    padding: 3px 0px 10px 0px;
}

input:checked + .slider {
  background-color: #2196F3;
}
#div2{
	    padding: 3px 0px 10px 0px;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
#div4{
	font-size:12px;
	padding-top: 1em;
}
.noti-text>p{
	margin-left: 1em;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* #div3{
	padding: 19px 0px 0px 0px;
} */

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
#id_welcome{
	color:#000;position: absolute;background: #fff;bottom: 16%;
	box-shadow: 3px 3px 30px grey, 0 0 5px black;
	font-size: 18px;
}
/*#id_welcome_window {
	color:#000;
	position: absolute;
	background: #fff;
	top: 50%;
	left: 8%;
	box-shadow: 3px 3px 30px grey, 0 0 5px black;
}*/

.slider.round:before {
  border-radius: 50%;
}
#get_boundary_detail{
	display: none;
}
#ex1Slider .slider-selection {
  background: #BABABA;
}
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 15px;

}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: green;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: -5px;
  background-color: white;
  border: 1px solid #D1CDCD;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: red;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;

}
.slider.round:before {
  border-radius: 50%;
}

#Layerbutton {
	position:absolute;
	left:284px;
	top:132px;
	width:203px;
	height:43px;
	z-index:3;
}

#loginpage {
	position:absolute;
	left:284px;
	top:132px;
	width:250px;
	height:43px;
	z-index:3;
}

#track_block3{
	display:none;height:368px;background:#ffffff;
}

#id_string1{width:80%;float:left;}
#id_string2{width:20%;padding:17px 1px 1px 12px;float:left;}
#id_red_status{width:100%;background:#f44242;float:left;height:22%}
#id_red_status1{width:50%;padding:18px 1px 1px 4px;float:left;color:#ffffff}
#id_red_status2{width:50%;float:left;padding:18px 1px 1px 35px;color:#ffffff}
#id_red_string{width:100%;height:31.5%;float:left;padding:25px 1px 1px 29px;}
#id_orange_status{width:100%;background:#ec701a;float:left;height:22%}
#id_orange_status1{width:50%;padding:18px 1px 1px 3px;float:left;color:#ffffff}
#id_orange_status2{width:50%;float:left;padding:18px 1px 1px 35px;color:#ffffff}
/*#veh_table1{height:316px;overflow-y:scroll;}*/


#noti_string{ width:75%;height:18%;background:red;float:left;background:#ffffff; }
#noti_string1{ width:100%;height:50%;font-size:26px;color:#ec701a;padding:23px 1px 1px 24px; }
#noti_string2{ width:100%;height:50%;font-size:17px;background:geern;color:#5d5b5a;padding:18px 1px 1px 24px; }
#noti_string_reply{ width:25%;height:18%;float:left; }
#NO{width:100%;height:50%;background:#5d5b5a;color:#ffffff;padding:26px 1px 1px 54px;font-size:24px;}
#YES{ width:100%;height:50%;background:#ec701a;color:#ffffff;padding:26px 1px 1px 54px;font-size: 24px; }


/* @media (min-width: 992px)
{
  h1{
    font-size:10px !important;
  }
} */
#child_account {
	background-color: #ffffff;
	width:100%;
	height:100%;
	float:left;
	left:123px;
	position: fixed;
	display:none;
	}


/* user profile css */
html{
	overflow-x: hidden;
}
.user_name>h1{
	font-size: 4em;
	color: #F0762B;
}
.logo-img-div>img{
	width: 300px;
	margin-top: .8em;
	margin-left: 1em;
}
.user_name>h5{
	margin-top: 1em;
}
.user_name>h4>a{
	text-decoration: none;
	font-size: 1.3em;
	color: #bdbdbd;
	cursor: pointer;
}
.blue-btn{
	color: #ffffff;
	background-color: #37383A;
	border-radius: 0px !important;
	box-shadow: 0px 3px 6px 0px #ccc;
}
.blue-btn:hover{
	color: #ffffff;
	background-color: #37383A;
	border-radius: 0px !important;
	box-shadow: 0px 3px 6px 0px #ccc;
}
.blue-btn:focus{
	color: #ffffff;
	background-color: #37383A;
	border-radius: 0px !important;
	border: none !important;
	box-shadow: 0px 3px 6px 0px #ccc;
	border: 0px !important;
}
.orange-btn{
width: 350px;
height: 40px;
border: 0;
/* font-weight: 400; */
border-radius: 10px;
font-size: 17px;
font-weight: bold;
background: #F2994A;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #F2C94C, #F2994A);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #F2C94C, #F2994A);
	
}
.orange-btn:hover{
	background-color: #fff;
	
	
}

.list-orange-btn{
	background-color: #F0762B;
	border-radius: 0px !important;
	color: #ffffff;
    font-size: 13px;
    box-shadow: 0px 3px 6px 0px #ccc;
}
.list-orange-btn:hover{
	background-color: #F0762B;
	border-radius: 0px !important;
	color: #ffffff;
    font-size: 13px;
    box-shadow: 0px 3px 6px 0px #ccc;
}
.lost-conn-m{
	margin-left: 3px;
}
.list-orange-btn:focus{
	background-color: #F0762B;
	border-radius: 0px !important;
	color: #ffffff;
    font-size: 13px;
    box-shadow: 0px 3px 6px 0px #ccc;
}
.default-color{
	background-color: #444549 !important;
	color: #ffffff !important;
}
.saved-destination-data>div{
	padding: 10px;
}
.view-data{
	border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    margin-top: 2em;
}
.car-data{
	border: 1px solid #e0e0e0;
	padding-left: 28px;
	background-color: #FCFCFC;
}
.car-data>div>h4{
	margin-top: -9px !important;
    margin-bottom: 15px !important;
}
.car-data>div>h3{
	color: #F0762B;
}
#terms-privacy>div>a{
	text-decoration: underline;
	color: #5E6168;
	text-shadow: 0px 3px 5px #8a8a8a;
}
/* .list-header{
	margin: 0em 8em 0em 13em;
	padding: 3em 0em 1em 2em;
} */
.list-header>div>h4{
	color: #afafaf;
}
/* .single-row{
	margin: 0em 8em 0em 13em;
	padding: 1em 0em 1em 2em;
} */
.single-row:nth-child(even){
	background-color: #f5f5f5;
}
#cars-data:nth-child(even){
	background-color: #f5f5f5;
}
.idel-rep-data:nth-child(even){
	background-color: #f5f5f5;
}
.center-text{
	text-align: center;
}
.close-modal {
    position: absolute;
    top: -20px;
    right: -16px;
}
.pro-margin{
	margin-left: 10em;
    margin-right: 10em;
}
.data-user-right{
	color: #F0762B;
}
.data-user-left{
	color: #797979;
}
.link-fire{
	width: 50px;
	height: 50px;
	text-align: center;
	border-radius: 50%;
	border: 1px solid #cccccc;
	color: #ffffff;
	cursor: pointer;
}
.link-fire>i{
	line-height: 1.6em;
}
.user-data>.row{
	padding: .7em 0em .7em 0em;
}
.reset-pass{
	text-align: left !important;
}
.reset-pass>h3{
	color: #F0762B;
}
.pro-footer{
	border-top: 0px;
	background-color: #fcfcfc;
}
.edit-pro-pencil{
	width: 20px;
    height: 20px;
    background-color: #ffffff;
    position: absolute;
    z-index: 999;
    bottom: 2px;
    right: 27px;
    border-radius: 50%;
    border: 1px solid #e0e0e0;
}
.cust-input{
	border: 0;
    border-radius: 0px !important;
    background: transparent;
    border-bottom: 1px solid #d4d4d4;
    outline: 0;
    box-shadow: 0px 0px 0px 0px !important;
}
.cust-input:focus{
	border: 0;
    border-radius: 0px !important;
    background: transparent;
    border-bottom: 1px solid #F0762B;
    outline: 0;
    box-shadow: 0px 0px 0px 0px !important;
}
.cal-div-new{
	width: 33%;
}
.old-pass{
	padding-top: 1.5em;
}
/* .old-pass>.col-md-7,.col-sm-7,.col-xs-7{
	padding-left: 0px !important;
	padding-right: 0px !important;
} */
.old-pass-btn{
	margin-top: 0px !important;
	margin-bottom: 7px !important;
	font-size: 15px !important;
}
.new-pass{
	padding-left: 0px !important;
	padding-right: 0px !important;
}
#destination-page>h5>img{
	padding-top: 46px !important;
}
/* .reset-new-pass>.col-md-6,.col-sm-6,.col-xs-6{
	padding-top: 1.1em !important;
	padding-bottom: 1.1em !important;
} */
.new-pass-btn{
	margin-top: 10px !important;
	margin-bottom: 35px !important;
	font-size: 15px !important;
	padding: .2em !important;
}
.option-list-new>h5{
	font-size: 16px;
}
.success-pass{
	text-align: center !important;
	padding-top: 3em;
	padding-bottom: 4em;
}
#reset-password{
	cursor: pointer;
}
#full_name{
	border: 0px;
	outline:0px;
	width: 236px;
	background: transparent;
}
#email_id{
	border: 0px;
	outline:0px;
	width: 236px;
	background: transparent;
}

/* new reports graph css */
.gp-header{
	background-color: #FAF8FA;
	/* padding: 0px 0px 0px 30px; */
	padding-left: 30px;
	padding-right: 9px;
	border-bottom: 1px solid #cacaca;
}
#gp-vehicle-list,#gp-vehicle-list-1,#gp-vehicle-list-1-table,#gp-vehicle-list-table-1,#driver-table-gp-vehicle-list{
	background-color: #ffffff;
	border: 1px solid #e0e0e0;
	box-shadow: 0px 3px 7px -1px #9a9a9a;
}
.list-scroll{
	max-height: 55%;
	overflow-y: auto;
	overflow-x: hidden;
}
.gp-select{
	 width: 20%;
	 position: relative;
}
.gp-select-child-1{
	width: 50%;
}
.gp-select-child-2{
	 width: 49%;
	 text-align: center;
	 position: absolute;
	 top: -12px;
}
#ignition_gp_list_export,#status_gp_list_export,
#trip_gp_list_export,#boundary_gp_list_export,
#driver-speed-export,#driver-harsh-acceleration-export,
#driver-harsh-braking-export{
	position: absolute;
    background: #5E5F68;
    left: 0px;
    width: 100%;
    padding: 4px 8px;
    box-shadow: 0px 5px 2px -2px #818591;
    border-radius: 3px;
}


#gp-driver-graph-div{
	margin: 0px 0px 34px 29px;
    overflow: auto;
    height: 100%;
    padding-bottom: 12em;
}
.gp-modes-child-2>a{
	text-decoration: none;
	color: #000000;
	cursor: pointer;
}
.gp-select-child-2-2>img{
	margin: auto; display: block; padding-top: 10px;
}
.gp-modes{
	/* width: 18%;  */
	border-left: 1px solid #cccccc;
	border-right: 1px solid #cccccc;
	text-align: center;
}
#mobile_no{
	border: 0px;
	outline:0px;
	width: 236px;
	background: transparent;
}
.gp-modes-child-1{
	width: 20%;
}
.gp-modes-child-2{
	width: 74%;
}
.gp-modes-child-2-1{
	width: 48%;
}
.gp-modes-child-2-2{
	width: 49%;
}
.gp-selection{
	width: 50%;
}
.gp-td-1{
	width: 18%;
}
.gp-td-2{
	width: 18%;
}
.gp-td-4{
	width: 13%;
	text-align: right;
}
.gp-td-3{
	text-align: center;
}
.gp-special{
	width: 11%;
	color: #ffffff;
	padding: 30px 30px 30px 0px;
	background-color: #5E5F68;
}
/* .gp-special>div>img{
	float: right;
}  */
.gp-selection-child-1{
	width: 41%;
	/* margin-right: 15px;
	margin-left: 15px; */
	text-align: left;
	margin-right: 8px;
	padding: 8px;
	border: 1px solid #e0e0e0;
    box-shadow: 0px 3px 7px -1px #9a9a9a;
    background-color: #ffffff;
}
.gp-ignition-count-child-1{
    background-color: #FAF8FA;
    padding-bottom: 10px;
}
.gp-graphs{
	border: 2px solid #e2e2e2;
	margin-top: 20px;
	margin-right: 30px;
	/* box-shadow: -4px 4px 8px -1px #cccccc; */
}
#gp-detailed-graph {
    margin-left: 30px;
    overflow: auto;
    height: 100%;
    padding-bottom: 12em;
}
.gp-ignition-count-child-2{
	padding: 25px;
}
.gp-ignition-count-child-2-3{
	background-color: #5E5F68;
	color: #ffffff;
	/* border-radius: 4px; */
	box-shadow: 0px 5px 2px -2px #818591;
}
#gp_export>div>span{
	cursor: pointer;
}
#gp-plot-graph{

}
.gp-ignition-count-child-2-3{
	padding: 8px;
	cursor: pointer;
	border-radius: 3px;
	/* position: absolute;
	right: 0px; */
	font-size: 13px;
	position: relative;
	top: -30px;
}
.tri-color-div{
	position: relative;
	cursor: pointer;
}
.veh-legend-wd{
	width: 24%;
}
.veh-legend-wd-sp{
	width: 23.7%;
}
.gp-ignition-count-child-2-1>div{
	/* padding: 10px; */

}
.gp-ignition-count-child-2-3>div>span{
	margin-left: 10px;
}
#gp-excel,#gp-pdf{
	margin-top: 5px;
}
/* .gp-ignition-count-child-2-2{
	margin-left: 3em;
} */

.new-css{
	max-width: 400px;
}
.gp-ignition-count-child-2-2>div>div{
	padding: 10px;
}
.driver-legends-first-vehicle{
  height: 12px;
  width: 12px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #FF0000;
}
.driver-legends-second-vehicle{
  height: 12px;
  width: 12px;
  margin-top: 5px;
  border-radius: 50%;
  background-color:#ec701a ;
}
.edit-driver-legends-first-vehicle{
  height: 12px;
  width: 12px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #FF0000;
}
.edit-driver-legends-second-vehicle{
  height: 12px;
  width: 12px;
  margin-top: 5px;
  border-radius: 50%;
  background-color:#ec701a ;
}

.first-vehicle{
	height: 30px;
	width: 30px;
	margin: auto;
	display: block !important;
	border-radius: 50%;
	background-color: #ec701a;
}
.second-vehicle{
	height: 30px;
	width: 30px;
	margin: auto;
	display: block !important;
	border-radius: 50%;
	background-color: #A44EC7;
}
.third-vehicle{
	height: 30px;
	width: 30px;
	margin: auto;
	display: block !important;
	border-radius: 50%;
	background-color: #4F91C8;
}
.fourth-vehicle{
	height: 30px;
	width: 30px;
	margin: auto;
	display: block !important;
	border-radius: 50%;
	background-color: #4FC76E;
}

.count-box{
	background-color: #ffffff;
    border-radius: 5px;
    text-align: center;
    padding: 3px 20px 3px 20px;
    /* margin-left: 5px;
    margin-right: 5px; */
    /* border-left: 5px solid #faf8fa;
    border-right: 5px solid #faf8fa;  */
    margin: 0px 5px;
}
.selected-opt,.selected-opt-1{
	padding: 5px 0px 5px 10px;
	width: 120px;
	background-color: #ffffff !important;
	border: 1px solid #000000;
}
.arrow-div>.glyphicon-triangle-top,.arrow-div-1>.glyphicon-triangle-top{
	color: #ffffff;
	font-size: 10px;
	margin-top: 2px;
}
.arrow-div,.arrow-div-1{
	height: 30px;
	padding: 3px;
	background-color: #5E5F68;
	position: absolute;
	top: 0px;
	cursor: pointer;
}
.hover-color{
	font-weight: ;
}
.new-row-width{
	width: 92%;
}
.graph-status-div{
	border-top-right-radius: 0px;
    border-bottom-right-radius: 100px;
    background-color: #5E5F68;
    text-align: center;
    color: #ffffff;
    padding: 12px 27px 0px 8px;
    /* margin-top: -11px; */
    /*border-bottom-style: ridge;*/
}
.graph-status-div>h5{
	font-size: 13px;
	font-weight: bold;
	margin-bottom: 0px;
	margin-top: 10px;
}
.count-box>h5{
	margin-top: 5px;
	margin-bottom: 5px;
	font-size: 13px;
}
.driver-speed-first-vehicle{
  height: 30px;
  width: 30px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #ec701a;
  display: block;
  margin: auto;
}
.driver-speed-second-vehicle{
  height: 30px;
  width: 30px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #A44EC7;
  display: block;
  margin: auto;
}
.driver-speed-third-vehicle{
  height: 30px;
  width: 30px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #4F91C8;
  display: block;
  margin: auto;
}
.driver-speed-fourth-vehicle{
  height: 30px;
  width: 30px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #4FC76E;
  display: block;
  margin: auto;
}


.driver-acc-first-vehicle{
  height: 30px;
  width: 30px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #ec701a;
  display: block;
  margin: auto;
}
.driver-acc-second-vehicle{
  height: 30px;
  width: 30px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #A44EC7;
  display: block;
  margin: auto;
}
.header-spacing{
	width: 95%;
    margin: auto;
    padding: 12px 10px 12px 4px;
}
.report-date{
	font-weight: bold;
    text-shadow: 0px 2px 4px #8a8a8a;
    font-size: 16px;
}
.driver-acc-third-vehicle{
  height: 30px;
  width: 30px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #4F91C8;
  display: block;
  margin: auto;
}
.driver-acc-fourth-vehicle{
  height: 30px;
  width: 30px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #4FC76E;
  display: block;
  margin: auto;
}

.driver-brake-first-vehicle{
  height: 30px;
  width: 30px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #ec701a;
  margin: auto;
  display: block;
}
.driver-brake-second-vehicle{
  height: 30px;
  width: 30px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #A44EC7;
  margin: auto;
  display: block;
}
.driver-brake-third-vehicle{
  height: 30px;
  width: 30px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #4F91C8;
  margin: auto;
  display: block;
}
.driver-brake-fourth-vehicle{
  height: 30px;
  width: 30px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #4FC76E;
  margin: auto;
  display: block;
}


.p-vehicle{
	height: 30px;
    width: 30px;
    margin: auto;
    display: block !important;
    border-radius: 50%;
    background-color: #ffffff;
	border: 2px solid #5E5F68;
}
.i-vehicle{
	height: 30px;
    width: 30px;
    margin: auto;
    display: block !important;
    border-radius: 50%;
	background-color: #ffffff;
	border: 2px solid #349DDE;
}
.m-vehicle{
	height: 30px;
    width: 30px;
    margin: auto;
    display: block !important;
    border-radius: 50%;
	background-color: #ffffff;
	border: 2px solid #ec701a;
}
.multi-vehicle{
	height: 20px;
    width: 20px;
    margin: auto;
    display: block !important;
    border-radius: 50%;
	background-color: #5E5F68;
}
.gp-selection-child-2{
	width: 41%;
	/* margin-right: 15px; */
	text-align: left;
	padding: 6px;
	margin-right: 8px;
	border: 1px solid #e0e0e0;
    box-shadow: 0px 3px 7px -1px #9a9a9a;
    background-color: #ffffff;
}
#gp-vehicle-list>ul{
	list-style: none;
	padding-left: 10px;
}
#gp-vehicle-clk>span,#gp-vehicle-clk-a>span{
	cursor: pointer;
}
.gp-selection-child-3{
	width: 10%;
}
/* End of new report css */

#page_one{
	text-align:center;
}

/* Boundary pages css */
.boundary-row{
   	height: 100%;
   	/* overflow-y: auto; */
   	overflow-y: hidden;
   	overflow-x: hidden;
}
.boundary-left-nav{
	color: #ffffff;
    background-color: #efefef;
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 4.2%;
    border-left: 1px solid #c3c3c3;
    box-shadow: -13px 0px 15px 10px #ababab;
}
.set-new-header>h2{
	font-size: 20px;
}
.boundary-left-nav-opt, .boundary-remove-css{
	/* margin: 3em 0em 3em 0em; */
	text-align: left;
    /* padding-top: .4em;
    padding-bottom: .4em;  */
    cursor: pointer;
    border-bottom: 1px dashed #cccccc;


}

/* floating btn css start*/
#floating-btn{
	position: fixed;
    bottom: 5%;
    left: 5%;
 	z-index: 99;
}
.float-btn{
	background-color: #ffffff;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	outline: 0;
	box-shadow: 0px 0px 9px 1px #bfbfbf;
}
.seekbar-div{
	background: linear-gradient(#ffffff, #f2f0f1);
}
.float-btn:focus{
	border: none;
	outline: 0;
}
#wel-div{
	position: fixed;
    bottom: 9.4%;
    z-index: 1;
    width: 19%;
    max-width: 295px;
    min-width: 286px;
    box-shadow: 0px 0px 9px 1px #bfbfbf;
}
#replay-route-new>img{
	width: 19%;
}
.rotate-right{
	transform: rotate(90deg);
	display: block;
}
.rotate-left{
	transform: rotate(-90deg);
	display: block;
}
#user-info{
	background-color: #f2f0f1;
	padding: .7em;
}
#user-info>div>a>img{
	width: 17%;
  height: 4%;
}
#marker-info>div{
	background-color: #f2f0f1;
	/* margin-left: .13em; */
	padding-top: .5em;
	padding-bottom: .5em;
	cursor: pointer;
	width: 22.8%;
	box-shadow: 0px 0px 10px 0px #bfbfbf;
	margin-bottom: -6px !important;
	margin-top: 6px !important;
}
#marker-info>div>img{
	width: 55%;
	display: block;
	margin: auto;
}
.margin-btm{
	margin-bottom: .4em;
}
#tool-tip-msg{
	background-color: #f2f0f1;
	padding: .8em 1em .8em 1em;
	position: absolute;
    width: 100%;
    top: 6px;
    box-shadow: 0px 2px 7px 1px #bfbfbf;
}
#tool-tip-msg>h5{
	margin: 0px;
}
#terms-list-view{
	left: 188px;
}
#car-count{
	font-size: 8px;
    background-color: #ec701a;
    border-radius: 50%;
    padding: 2px;
    color: #ffffff;
    position: absolute;
    top: 16px;
    height: 12px;
    width: 12px;
    text-align: center;
    line-height: 7px;
    margin-left: -5px;
    font-weight: bold;
}
.location-new>div>img{
	width: 50% !important;
	margin: auto;
	display: block;
}
.total-trips-new>div>img{
	width: 59% !important;
    padding-top: .5em;
    padding-bottom: .5em;
    margin-left: 11%;
}
.driver-name-new>div>a>img{
	width: 26% !important;
	margin: auto;
	display: block;
}
.single-car-list{
	 background-color: #ffffff;
	 padding: 0em 0em 0em 1em;
}
.new-search{
	border-radius: 0px;
}
.border-pipe{
	border-left: 1px solid #f0f0f0;
}
#car-list-new{
	max-height: 208px;
    overflow: auto;
    overflow-x: hidden;
}
#vehicle-list-clk{
	cursor: pointer;
}
#live-replay-new{
	background-color: #f2f0f1;
	cursor: pointer;
}
#live-track-new{
	padding: 1.3em 0em 1.3em 1em;
}
#replay-route-new{
	padding: 1.3em 1em 19px 0em;
}
.act-opt{
	color: #ffffff;
	background-color: #5E6168;
	padding: 15px;
}
.new-switch{
	position: absolute;
	right: 20%;
}
#new-setting{
	position: fixed;
	top: 120px;
	left: 90px;
	width: 15%;
	min-width:210px;
	max-width: 230px;
	/* padding-top: 10px; */
	height: 200px;
	background-color: #ffffff;
}
#vehicle-search-new{
	cursor: pointer;
	line-height: 2.5;
	margin-right: 5px;
}
#close-search-new{
	line-height: 2.5;
	margin-right: 5px;
	cursor: pointer;
}
.arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: fixed;
  border-right:10px solid #ffffff;
}

#lt-vehicle-detail,#rr-vehicle-detail{
	background-color: #ffffff;
}
.triangle{
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	position: absolute;
	border-bottom: 5px solid #f2f0f1;
}

.detail-div-new{
	border: 1px solid #f2f2f2;
	margin-bottom: .5em;
	padding: 10px;
}
.odometer-edit-new{
	padding: .5em 1em .5em 1em;
	background-color: #f2f0f1;
}
.car-clk{
	cursor: pointer;
}
/* floating btn css end*/

/*.boundary-left-nav-opt:checked + label  {
  color: #ffffff;
}*/
.boundary-left-nav-opt>h5{
	/* margin-left: .8em; */
	font-size: 18px;
	margin: 0px;
    font-weight: bold;
}
.boundary-left-nav-opt>h5>img, .boundary-remove-css>h5>img{
	margin: auto;
	display: block;
	/* width: 50%; */
	/* padding-top: 36%; */
}
.boundary-styling-nav{
  background-color: #ffffff !important;
  color: #ec701a !important;
}
#vehicle_id_css {
	background-color: #ec701a !important;
  color: #37383A !important;
}
.boundary-remove-css{
	/* margin: 3em 0em 3em 0em; */
	text-align: left;
    /* padding-top: .4em;
    padding-bottom: .4em;  */
    cursor: pointer;
}
.boundary-remove-css>h5{
	/* margin-left: .8em; */
	margin: 0px;
	font-size: 18px;
    font-weight: bold;
}
.bound-header{
	color: #ec701a;
	padding: 4.1em 0em 0em 2.1em;
}
.map-ht-v{
	padding-left: 4.1em !important;
}
.bound-header>h2{
	margin-left: 1em;
}
#boundary-body{
	text-align: center;
	margin-top: 14em;
}
.create-footer-text{
	text-align: center;
	margin-top: 10em;
}
.create-footer-text>h4{
	font-size: 17px;
	line-height: 1.5em;
	color: #5a5a5a;
}
#boundary-body>div>button{
	margin-top: 1em;
	padding-left: 3em;
	padding-right: 3em;
}
#boundary-body>div>h4{
	padding-top: 15em;
}
#boundary-body>div>h3{
	color: #ababab;
}
#speed-body{
	text-align: center;
	margin-top: 14em;
}
#speed-body>div>button{
	margin-top: 1em;
	padding-left: 3em;
	padding-right: 3em;
}
#speed-body>div>h4{
	padding-top: 10em;
}
#speed-body>div>h3{
	color: #ababab;
}
.page-1-opt{
	padding-left: 0px;
	/* box-shadow: -37px -18px 21px -37px #1f1f1f; */
	width: 95.8%;
}
.view-bound{
	display: inline;
	margin-right: 1em;
	padding-left: 3em;
	padding-right: 3em;
}
.create-new-bound{
	display: inline;
	margin-right: 2em;
	padding-left: 3em;
	padding-right: 3em;
}
.set-new-header{
	color: #ec701a;
	padding-top: 4.2em;
	padding-right: 3em;
	padding-left: 2em;
}
.set-new-header>h2{
	margin-left: .5em;
}
.setting-header{
	color: #ec701a;
	padding-top: 4.1em;
}
.setting-header>h2{
	margin-left: 1.8em;
}
.enter-bound{
	padding-left: 2.1em;
}
.enter-bound>div{
	padding-top: 2em;
	padding-bottom: 2em;
}
.car-name>div{
	padding-top: .5em;
	padding-bottom: .5em;
}
.enter-bound>>div>h5{
	color: #000000;
	font-size: 17px;
}
#bound-select_vehicle>input{
	font-size: 20px;
}
.new-bound-save{
	padding-left: 4em;
	padding-right: 4em;
}
.new-speed-save{
	padding-left: 3.3em;
	padding-right: 4em;
	font-size: 18px; /*25px;*/
}
.bound-drop-arrow{
	float: right;
	cursor: pointer;
    margin-right: 6px;
    margin-top: -30px;
    position: relative;
    z-index: 2;
}
#drv_veh{
  padding: .8em 0em .8em 1em;
    box-shadow: 0px 0px 10px 0px #ccc;
    background-color: #fafafa;
}
#car-list{
	box-shadow: 0px 2px 6px 3px #e0e0e0;
	position: absolute;
    width: 88%;
    background-color: #ffffff;
    z-index: 9;
    padding-top: .5em;
    padding-bottom: 1em;
}
#car-list>div{
	padding-top: .4em;
	padding-bottom: .4em;
	padding-left: 1em;
}
#speed-car-list{
	box-shadow: 0px 2px 6px 3px #e0e0e0;
	position: absolute;
    width: 88%;
    background-color: #ffffff;
    z-index: 9;
    padding-top: .5em;
    padding-bottom: 1em;
    overflow-y:scroll;
    overflow-x:hidden;
    height:18em;
    cursor: pointer;
}
#boundary-car-list{
	box-shadow: 0px 2px 6px 3px #e0e0e0;
	position: absolute;
    width: 88%;
    background-color: #ffffff;
    z-index: 9;
    padding-top: .5em;
    padding-bottom: 1em;
    overflow-y:scroll;
    overflow-x:hidden;
    height:18em;
    cursor: pointer;
}
#hrs-list{
	box-shadow: 0px 2px 6px 3px #e0e0e0;
	position: absolute;
    width: 89%;
    background-color: #ffffff;
    z-index: 9;
    padding-top: .5em;
    padding-bottom: 1em;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 21em;
    cursor: pointer;
}
#minutes-list{
	box-shadow: 0px 2px 6px 3px #e0e0e0;
	position: absolute;
    width: 89%;
    background-color: #ffffff;
    z-index: 9;
    padding-top: .5em;
    padding-bottom: 1em;
    overflow-y: scroll;
    overflow-x:hidden;
    height: 21em;
    cursor: pointer;
}
#boundary-car-list>div{
	padding-top: .4em;
	padding-bottom: .4em;
	padding-left: 1em;
}
#speed-car-list>div{
	padding-top: .4em;
	padding-bottom: .4em;
	padding-left: 1em;
}
#setting-car-list{
	box-shadow: 0px 2px 6px 3px #e0e0e0;
	position: absolute;
    width: 88%;
    background-color: #ffffff;
    z-index: 9;
    padding-top: .5em;
    padding-bottom: 1em;
    overflow-y:scroll;
    overflow-x:hidden;
    height:18em;
    cursor: pointer;
}
#destination-vehicle-list{
  box-shadow: 0px 2px 6px 3px #e0e0e0;
	position: absolute;
    width: 89%;
    background-color: #ffffff;
    z-index: 9;
    padding-top: .5em;
    cursor: pointer;
}
#vehicle-location-alarm-vehicle-list{
  box-shadow: 0px 2px 6px 3px #e0e0e0;
  position: absolute;
  width: 88%;
  background-color: #ffffff;
  z-index: 9;
  padding-top: .5em;
  padding-bottom: 1em;
}

#setting-car-list>div{
	padding-top: .4em;
	padding-bottom: .4em;
	padding-left: 1em;
}
.drop-up{
	display: block;
    margin: auto;
    padding-top: .6em;
}
.li-class{
	background-color:#ec701a;
	color:#ffffff;
}

.child_account_vehicle_name{
	background-color:#ec701a;
	color:#ffffff;
}
.list-li-class{
	background-color:#ec701a;
	color:#ffffff;
}
.ok-edit:hover {
    cursor: pointer;
}
.delete-edit:hover {
    cursor: pointer;
}
.child-account-car-name{
	background-color: #ffffff;
	color: #000000;
}
.bound-li-class{
	background-color:#ec701a;
	color:#ffffff;
}

.boundary_vehicle_css{
	background-color: #ffffff;
	color: #000000;
}
#vehicle_id>h4{
	background-color:#ec701a;
	color:#ffffff;
}
.speed-li-class{
	background-color:#ec701a;
	color:#ffffff;
}
.vehicle-selected-style{
	background-color:#ffffff;
	color:#000000;
}
.radius-class{
	background-color:#ec701a;
	color:#ffffff;
    box-shadow: 0px 3px 5px 0px #9a9a9a;
}
#select-radius{
	box-shadow: 0px 2px 6px 3px #e0e0e0;
    position: absolute;
    width: 88%;
    background-color: #ffffff;
    z-index: 9;
}
#select-radius>div{
	padding-left: 1em;
}
.kilo-data{
	text-align: center;
	padding-bottom: 1em;
}
.kilo-data>div{
	padding-right: 1.8em;
}
.kilo-data>div>h4{
	padding-top: 1em;
	padding-bottom: 1em;
}
#chd_acc{
        text-align: center;
        padding-top: 10em;
}
#chd_acc>div>h4{
        padding-top: 15em;
}
#destination-body{
	text-align: center;
    margin-top: 14em;
}
#destination-body>div>h4{
        padding-top: 7em;
}
#change-password-body{
    text-align: center;
    padding-top: 17em;
}
#change-password-body>div>h4{
   padding-top: 15em;
}
/* #create-vla{
	padding-left: 1.2em;
} */
#view-vla{
	margin-left: 4em;
	margin-right: 4em;
	padding-top: 1em;
}
#view-vla>div{
	margin: 0px;
}
#vla-body{
	text-align: center;
    margin-top: 14em;
}
#vla-body>div>h3{
   color: #909090;
}
#boundary_map{
   	position: relative! important;
   	overflow: hidden! important;
}
.saved-speed-data>div>h5{
	font-size: 14px;
}
#destination_map{
    position: relative! important;
    overflow: hidden! important;
}
#alarm_map{
    position: relative! important;
    overflow: hidden! important;
}
#vehicle-list{
        box-shadow: 0px 2px 6px 3px #e0e0e0;
        position: absolute;
    width: 88%;
    background-color: #ffffff;
    z-index: 9;
    padding-top: .5em;
    padding-bottom: 1em;
}
#vehicle-list>div{
        padding-top: .4em;
        padding-bottom: .4em;
        padding-left: 1em;
}
####################location alarm##############33
#location_alarm_hrs_list{
        box-shadow: 0px 2px 6px 3px #e0e0e0;
        position: absolute;
    width: 88%;
    background-color: #ffffff;
    z-index: 9;
    padding-top: .5em;
    padding-bottom: 1em;
}
#location_alarm_hrs_list>div{
        padding-top: .4em;
        padding-bottom: .4em;
        padding-left: 1em;
}
#############location alarm end###############
#hrs-list{
        box-shadow: 0px 2px 6px 3px #e0e0e0;
        position: absolute;
    width: 88%;
    background-color: #ffffff;
    z-index: 9;
    padding-top: .5em;
    padding-bottom: 1em;
}
#hrs-list>div{
        padding-top: .4em;
        padding-bottom: .4em;
        padding-left: 1em;
}
############location alarm minute list##########
#location-alarm-minutes-list{
        box-shadow: 0px 2px 6px 3px #e0e0e0;
        position: absolute;
    width: 88%;
    background-color: #ffffff;
    z-index: 9;
    padding-top: .5em;
    padding-bottom: 1em;
}
#location-alarm-minutes-list>div{
      padding-top: .4em;
        padding-bottom: .4em;
        padding-left: 1em;
}
##############3location alarm minute listt end############
#minutes-list{
        box-shadow: 0px 2px 6px 3px #e0e0e0;
        position: absolute;
    width: 88%;
    background-color: #ffffff;
    z-index: 9;
    padding-top: .5em;
    padding-bottom: 1em;
}
#minutes-list>div{
        padding-top: .4em;
        padding-bottom: .4em;
        padding-left: 1em;
}
.bound-success-msg{
	text-align: center;
	color: #ababab;
	padding-top: 16em;
}
.speed-success-msg{
	text-align: center;
	color: #ababab;
	padding-top: 16em;
}
.search-box{
	width: 280px;
	border-radius: 0px !important;
	padding: 1em;
    box-shadow: 0px 4px 9px -1px #ccc;
}
#view-bound-data{
	padding-left: 4em;
    padding-right: 4em;
	max-height: 80%;
}
.header-bound-data{
	border-bottom: 1px solid #e0e0e0;
	border-top: 1px solid #e0e0e0;
	margin-top: 1.2em;
}
.header-bound-data>div>h4{
	color: #ababab;
}
.saved-destination-data>div>h5{
	font-size: 14px;
}
/*.header-speed-data{
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  margin-top: 1.2em;
}
.header-speed-data>div>h4{
  color: #ababab;
}*/
.saved-bound-data>div>h5{
	font-size: 12px;
}
.saved-bound-data>div>h6{
	font-size: 12px;
}
.saved-bound-data{
	padding-top: .3em;
	padding-bottom: .3em;
}
.saved-bound-data:nth-child(even){
	background-color: #f5f5f5;
}
.saved-speed-data:nth-child(even){
	background-color: #f5f5f5;
}
.saved-destination-data:nth-child(even){
	background-color: #f5f5f5;
}
.edit-pro-pencil1{
	width: 20px;
    height: 20px;
    background-color: #ffffff;
    position: absolute;
    cursor: pointer;
    z-index: 999;
    bottom: 0px;
    border-radius: 50%;
    border: 1px solid #e0e0e0;
  }
.cust-hr{
	margin-left: -56px;
    margin-right: -56px;
    border: 6px solid #444549;;
}
.view-boundaries{
	display: inline;
	line-height: 18px;
	cursor: pointer;
}
.view-boundaries>a{
	color: #ec701a;
	text-decoration: none;
}
.ok-edit{
	color: #47B198;
}
.ok-edit>h6>span{
	padding-right: .3em;
}
.delete-edit>h6>span{
	padding-right: .3em;
}
.delete-edit{
	padding-right: .3em;
	color: #C8222D;
}
.theme-color{
	color: #ec701a;
}
.set-new-header>button{
	margin-right: 2em;
	padding-left: 3em;
    padding-right: 3em;
}
#speed-vehicles{
	cursor: pointer;
}
#speed-vehicles>input{
	font-size: 17px !important;
	cursor: pointer;
}
#setting-vehicles{
	cursor: pointer;
}
#setting-vehicles>input{
	font-size: 20px;
	cursor: pointer;
}
#edit-speed-alert-head{
	border-bottom: 1px solid #e0e0e0;
	padding-left: 1em;
}
#edit-speed-alert-head>div>div>h6{
	font-size: 14px !important;
    color: #ababab;
}
.edit-speed-alert-data{
	padding-left: 1em;
}
.edit-speed-alert-data>div{
	padding-top: .8em;
    padding-bottom: .8em;
}
.edit-speed-alert-data>div>div>h5{
	font-size: 18px !important;
}
.ok-edit>h6{
	font-size: 14px !important;
}
.delete-edit>h6{
	font-size: 14px !important;
}
.edit-speed-alert-data:nth-child(odd){
	background-color: #f5f5f5;
}
#setting-data{
    padding-left: 3.4em;
}
#setting-data>div{
	padding-top: 2.2em;
	padding-bottom: 1em;
}
/* slider css*/
*, *:before, *:after {
  box-sizing: border-box;
}



.range-slider {
  margin: 60px 0 0 0%;
}

.range-slider {
  width: 100%;
}


.range-slider__range {
  -webkit-appearance: none;
  width: calc(100% - (73px));
  height: 10px;
  border-radius: 5px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 0;
}
.range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
          appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ec701a; /*#2c3e50;*/
  cursor: pointer;
  -webkit-transition: background .15s ease-in-out;
  transition: background .15s ease-in-out;
}
.range-slider__range::-webkit-slider-thumb:hover {
  background: #ec701a; /*#1abc9c;*/
}
.range-slider__range:active::-webkit-slider-thumb {
  background: #ec701a; /*#1abc9c;*/
}
.range-slider__range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: #2c3e50;
  cursor: pointer;
  -webkit-transition: background .15s ease-in-out;
  transition: background .15s ease-in-out;
}
.range-slider__range::-moz-range-thumb:hover {
  background: #1abc9c;
}
.range-slider__range:active::-moz-range-thumb {
  background: #1abc9c;
}

.range-slider__value {
  display: inline-block;
  position: relative;
  width: 30px;
  color: #000; /* #fff */
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: #fff; /*#ec701a;*/ /*#2c3e50;*/
  padding: 0px 0px;
  margin-left: 43px;
  margin-top: 10px;
}
.range-slider__value:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid #fff; /*#ec701a;*/ /*#2c3e50;*/
  border-bottom: 7px solid transparent;
  content: '';
}

::-moz-range-track {
  background: #d7dcdf;
  border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}

/* slider css end */
/* toggle switch */
.material-switch > input[type="checkbox"] {
    display: none;
}

.material-switch > label {
    cursor: pointer;
    height: 0px;
    position: absolute;
    width: 40px;
}

.material-switch > label::before {
    background: #C82226; /*#C8222D; /*#000; /*rgb(0, 0, 0);*/
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position:absolute;
    opacity: 1; /*0.3;*/
    transition: all 0.4s ease-in-out;
    width: 40px;
}
.material-switch > label::after {
    background: #fff; /*47B198; /* rgb(255, 255, 255); */
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
}
/* #C8222D  #47B198  #5fceb0*/
.material-switch > input[type="checkbox"]:checked + label::before {
    background: #49b198; /*#5fceb0; /* inherit; */
    opacity: 1; /*0.5;*/
}
.material-switch > input[type="checkbox"]:checked + label::after {
    background: #fff; /* inherit; */
    left: 20px;
}
.cal-image{
	margin-left: 20px;
	top: -32px;
}
.click-graph-ev{
	padding-left: 8%;
}
.selection-time{
	width: 9%;
}
#switch{
  padding-top: .3em;
}
/* toggle switch end */
/* Home page notification and modal css */
.click-notify{
	background: #ffffff;
	box-shadow: 0px 0px 30px 0px #ccc;
}
.noti-header{
	color: black;
	background-color: #f0f0f0;
	padding-left: 14px;
	font-weight: bold;
}
#all-notification{
    position: fixed;
    z-index: 99999;
    background: #ffffff;
    box-shadow: 0px 4px 13px 0px #bfbfbf;
}
.noti-content{
    border-bottom: 1px solid #d6d6d6;
}
.noti_parent{
	cursor: pointer;
}
.noti-content>div>img{
	padding-top: .4em;
}
.noti-content-1{
	background-color: #f0f0f0;
}
.noti-img-clk{
	background-color: #f0f0f0;
}
#bell-div{
	cursor: pointer;
}
.noti-header>h2{
	margin-bottom: 20px !important;
}
/* #odometer_height{
  height: 138px !important;
} */
.more-det{
	color: #ec701a;
	font-style: italic;
	font-weight: bold;
}
.search-icon{
    position: absolute;
}
.close-icon{
    position: absolute;
    right: 1em;
}
#search-data-click>input{
	/*padding: 1.3em;
    font-size: 16px;
    padding-left: 2em;
    border: none;
    outline: 0;
    border-bottom: 3px solid #b7b7b7;
    padding-bottom: 1em;*/
}
#quick-search{
    position: absolute;
    z-index: 11;
    background: #ffffff;
    box-shadow: 0px 3px 15px -1px #bfbfbf;
}
.quick-header{
	background-color: #FAFAFA;
}
.zero{
	margin: 0px;
}
.search-css{
	padding: 0.2em 0 0.2em 1.6em;
	margin:0 0 0 0;
}
.search-css div{
	padding:0.2em 0 0.2em 0;
}
.noti-count{
	background-color: #ec701a;
	color: #ffffff;
	width: 25px;
	height: 15px;
	border-radius: 10px;
	position: fixed;
	font-size: 11px;
	font-weight: bold;
	text-align: center;
	top: 16px;
}

.div-body{
	background-color: rgba(0,0,0, 0.7);
	position: absolute;
	z-index: 9999;
	width: 100%;
	height: 100%;
}
.noti-msg{
    background-color: #ffffff;
    position: absolute;
    top: 42%;
    left: 32%;
    padding: 0px !important;
}
.noti-msg-1{
    position: absolute;
    top: 40%;
    right: 25.5%;
    padding: 0px !important;
    cursor: pointer;
}
.yes_no_clk>div>h2{
	margin-top: 0px;
	margin-bottom: 0px;
	line-height: 3.5em;
	text-align: center;
	color: #ffffff;

}
.yes_no_clk>div>h4{
	margin-top: 5.5em;
	margin-bottom: 0px;
	line-height: 5.2em;
	text-align: center;
	background-color: #F0762B! important;
	color: #ffffff! important;

}
.noti-texts{
    padding-left: 3em !important;
    padding-top: 2em;
}
.subscribe-notification{
	background-color: #F0762B;
}
.unsubscribe-notification{
	background-color: #5E6168;
}
.subscribe-notification>h2{
	font-size: 20px;
}
.unsubscribe-notification>h2{
	font-size: 20px;
}
/*.yes{

	background-color: red! important;
	color: #ffffff! important;
}
.no{

	background-color: #5E6168! important;
	color: #ffffff;
}*/

#car-stat>div{
	background-color: #ffffff;
	position: relative;
	top: 300px;
	padding: 0px !important;
}
.stat-center{
	text-align: center;
}
.stat-center>h6{
	color: #ec701a;
	font-size: 13px !important;
}
.stat-center>h4{
	color: #5E6168;
	font-size: 20px !important;
}
.stat-head{
	margin: 0px !important;
    background-color: #37383A;
    color: #ec701a;
    padding: 0.5em 0em 1em 1.8em !important;
    box-shadow: 0px 15px 9px -5px #d4d4d4;
}
.stat-footer{
	color: #ffffff;
	text-align: center;
	background-color: #5E6168;
	padding: .7em 0em .7em 0em;
}
.stat-footer>h5{
	margin: 0px;
}

/* Replay route and live track modal css */
#live-replay{
	position: absolute;
	background-color: #ffffff;
	z-index: 9;
	box-shadow: 0px 7px 20px 0px #909090;
}
.lp-car-details{
	text-align: center;
	border: 1px solid #e0e0e0;
}
.lp-car-details>h3{
	color: #ec701a;
}
.lp-header{
    border-bottom: 1px solid #cccccc;
}
.orange-border{
	background-color:#ec701a;
	padding: 2px !important;
	box-shadow: 0px 3px 8px 0px #5f5f5f;
}
.lp-click-bg{
	background-color: #000;
    color: #ffffff;
}
#close-lp{
	position: absolute;
    top: -14px;
    right: -11px;
}
.lp-header>div{
	padding: 1em 0em 1em 0em;
}
#div-6-more-car>div{
	position: relative;
    z-index: 9;
    padding-left: 0px !important;
}
#div-car-6{
    position: absolute;
    z-index: 9;
    background: #ffffff;
    box-shadow: 0px 7px 20px 0px #909090;
}
#div-6-more-car{
	position: absolute;
    z-index: 9;
    background: #ffffff;
    box-shadow: 0px 7px 20px 0px #909090;
    padding: 0px !important;
}
#lp-right-car-lst-more{
	position: absolute;
    z-index: 9;
    background: #ffffff;
    box-shadow: 0px 7px 20px 0px #909090;
    padding: 0px !important;
}
#lp-car-status{
	position: absolute;
    z-index: 9;
    background: #ffffff;
    box-shadow: 0px 7px 20px 0px #909090;
}
#lp-car-status>div>div{
	padding-top: .5em;
}
.search-vehicle{
	border: none !important;
    font-size: 14px !important;
    padding: 1.5em 1em 1.5em 1em !important;
}
.car-list-more>div{
	padding-left: 2em;
	border: .5px solid #f2f2f2;
}
.car-list-more-1>div{
	padding-left: 2em;
	border: .5px solid #f2f2f2;
}
.car-list-more{
	margin: 0px !important;
}
.car-list-more-1{
	margin: 0px !important;
}
.grid-footer{
	background-color: #37383A;
	padding: 1em;
}
.grid-car-count>h4{
	color: #ec701a;
}
.lp-car-detail{
	background-color: #ec701a;
	color: #ffffff;
}
.conn-car-detail{
	background-color: #EE2B40!important;
	color: #ffffff;
}
.idling-car-detail{
	background-color: #369ADA;
	color: #ffffff;
}
.parked-car-detail{
	background-color: #5E6168;
	color: #ffffff;
}
.lt-car-detail{
	border-bottom: 1px solid #e0e0e0;
	border-top: 1px solid #e0e0e0;
}
.lp-car-detail>div>h3{
	margin-top: 0px !important;
}
.conn-car-detail>div>h3{
	margin-top: 0px !important;
}
.idling-car-detail>div>h3{
	margin-top: 0px !important;
}
.parked-car-detail>div>h3{
	margin-top: 0px !important;
}
.lt-car-detail>div>h3{
	margin-top: 0px !important;
	color: #ec701a;
}
/* .lp-edit-car-dt>div>div>h5{
	font-size: 16px !important;
}
.idling-edit-car-dt>div>div>h5{
	font-size: 16px !important;
}
.parked-edit-car-dt>div>div>h5{
	font-size: 16px !important;
} */
.idling-edit-car-dt>div{
	padding-top: .6em;
	padding-bottom: .6em;
}
.parked-edit-car-dt>div{
	padding-top: .6em;
	padding-bottom: .6em;
}
#lp-right-car-lst{
	position: absolute;
    z-index: 9;
    background: #ffffff;
    box-shadow: 0px 7px 20px 0px #909090;
}
#lp-right-car-lst>div>div{
	border: 1px solid #efefef;
}
#lp-right-car-lst>div>div>h4{
	color: #ec701a;
}
.lt-date{
	background-color: #F9F9F9;
	border-bottom: 1px solid #e0e0e0;
}
.lt-date>div>h4{
	margin-top: 0px !important;
	margin-bottom: 0px !important;
}
.current-lt .lt-date>div{
	text-align: left;
}
.current-lt{
	border-bottom: 1px solid #e0e0e0;
}
.total-dist{
	background-color: #ec701a;
	color: #ffffff;
	text-align: center;
	box-shadow: 0px 2px 25px 1px #909090;
}
.total-dist>h4{
	font-size: 16px;
}
.play-replay{
	padding-bottom: 0px !important;
}
.tooltip > .tooltip-inner {background-color: #e0e0e0; color: #000;}
.tooltip > .tooltip-arrow {border-bottom-color: #e0e0e0; color: #000;}
{
	color: #ffffff !important;
	text-decoration: none;
}
#forget_pass{
	padding-top: 22em;
	padding-bottom: 3em;
}
#success_pass{
	padding-top: 26em;
	padding-bottom: 3em;
}
#enter-pass{
	padding-top: 22em;
	padding-bottom: 3em;
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}
.for-inpu{
	border-radius: 0px !important;
    padding: 1.7em !important;
}
.clk-login{
	text-align: center;
	padding-top: 3em;
}
.clk-login>a{
	color: #696969;
	font-size: 20px;
}
#proceed-for:hover{
	color: #ffffff !important;
}
#forget-sub:hover{
	color: #ffffff !important;
}

.login-input{
	border: 0px !important;
    border-radius: 0px !important;
    padding: 1.7em !important;
}
/* #create-driver{
	padding-left: 8.3em;
} */
#driver-body{
	text-align: center;
	padding-top: 17em;
}
#driver-body>div>button{
	padding-left: 8em;
    padding-right: 8em;
    margin-top: 1em;
}
#driver-body>div>h3{
	color: #ababab;
}
#driver-body>div>h4{
	padding-top: 15em;
}
.view-driver{
	margin-right: 2em;
	padding-left: 5em;
	padding-right: 5em;
}
.bound-header>div>h2{
	margin-top: 0px !important;
	margin-bottom: 0px;
}
.driver-img{
  height: 125px! important;
  width: 125px! important;
	/* display: block;
	margin: auto; */
}
.edit-driver-pencil{
	width: 25px;
    height: 25px;
    background-color: #ffffff;
    position: absolute;
    bottom: 0px;
    border-radius: 50%;
    border: 1px solid #e0e0e0;
}
.search-icon{
	float: right;
    margin-right: 30px;
    position: relative;
    z-index: 2;
}
.driver-detail>div{
	margin-top: 2em;
}
.class_boud_nm,.results>span{
	word-break: break-all;
}
.parent_result:hover{
	background-color: transparent !important;
}
.results:hover{
	color: #ec701a !important;
}
#boundary-bound-select-vehicle>input,
#search_boundary_map,
#boundary_tag,
#search_edit_boundary_map,
.close-modal{
	cursor: pointer;
}
.search-veh{
	padding: .8em 0em .8em 1em;
    box-shadow: 0px 0px 1px 0px #ccc;
    background-color: #fafafa;
}
.driver-car-list{
	border: 1px solid #e8e8e8;
	max-height: 15em;
    overflow: overlay;
}
.driver-car-list>div{
	padding: .5em .5em .5em 1em;
}
/* .driver-car-list>div>label{
	font-size: 18px !important;
} */
#assign-shift{
	border: 1px solid #e8e8e8;
    position: absolute;
    box-shadow: 0px 0px 13px 0px #ccc;
}
.shift-time>div{
	padding-top: 1em;
	padding-bottom: 1em;
}
.shift-time{
	padding: 1em 0em 0em 1em;
}
.time-list{
	box-shadow: 0px 2px 6px 3px #e0e0e0;
	position: absolute;
    width: 88%;
    background-color: #ffffff;
    z-index: 9;
    padding-top: .5em;
}
.time-list>div{
	border-bottom: 1px solid #ccc;
	text-align: center;
}
.created-shift{
	background-color: #fafafa;
	padding: .7em;
}
.created-shift>div{
	padding-left: 1.4em;
}
#ad-success>div>h3{
	color: #909090;
	line-height: 1.3em;
}
#ad-list{
	margin-left: 4em;
	margin-right: 4em;
	padding-top: 3em;
}
#ad-list>div{
	margin: 0px;
}
.loading{
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: url(/static/images/loader.gif) center no-repeat #fff;
}
/* #imageBox1{
	border-radius:50%;
	border: 1px solid #cccccc;
} */

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #000;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #000;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #000;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #000;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    #000;
}
.notification-img{
	display: block;
	margin: auto;
}
#go_date{
    text-align: center;
    cursor: pointer;
}
.left-nav-opt{
	cursor: pointer;
}
.report-data-row{
	border-top: 1px solid #dadada;
	border-bottom: 1px solid #dadada;
	padding: 1em 3em 1em 2.2em;
}
.down-report,.car-ignition{
	padding: 1em 2em 1em 2em;
}
.car-ignition,.graph-div,.towing-div{
	padding: 1em 3em 1em 3em;
}
.down-report>div>div>h3{
	font-size: 19px;
}
.car-ignition>div>h4{
	font-size: 17px;
	font-weight: 600;
}
@media (max-width: 767px) {
#button_sized {
  width:23%;
}
}
.well{
	padding: 6px !important;
}
.report-header{
	padding: 3em 3em 1em 3em;
	color: #ec701a;
}
.on-down-data>div>div>h3{
	font-size: 19px;
}
.on-down-data{
	padding: 1em 3em 1em 3em;
}
.child-div{
	text-align: center;
	padding-top: 1em;
}
.divide-div{
	margin-top: .5em;
}
#driver_performance{
	text-decoration: underline;
	cursor: pointer;
}
#driver_performance:hover{
	text-decoration: underline;
	cursor: pointer;
	color: #ec701a;
}
.left-nav-opt{
	padding: 36px 17px 17px 17px;
}
#driver_report_filter{
	margin-top: 2em;
}
#add-details-vla{
	padding-right: 2em;
}
/* .user_name{
	padding-top: 1.5em;
} */
/* .profile-btn{
	padding-top: 5em;
} */
.profile-row{
	padding: 2em 2em 0em 2em;
}
.view-data>div>h3{
	font-size: 20px;
}
.view-data{
	padding: 0em 2.7em 0em 0em;
}
.grid-img,.list-img{
	cursor: pointer;
	color: #9A9EA1;
}
.car-data>div>h2{
	font-size: 21px;
}
.list-view{
	margin-left: 1em;
	margin-right: 2em;
	margin-top: 2em;
}
.list-header{
	border-bottom: 1px solid #dedede;
}
.single-row{
	padding: .5em 0em 1em 0em;
}
.driver-detail>div>input{
	font-size: 15px !important;
}
#select-start-hrs>img,#select-start-meridien>img,#select-end-hrs>img,#select-end-meridien>img{
	width: 25%;
}
.report-car-name{
	border-bottom: 1px solid #cccccc;
	cursor: pointer;
}
.child-div>img{
	width: 20%;
}
.float-btn:focus{
	outline: none;
}
.profile-img{
	width: 7.4%;
}
.user_name{
	width: 20%;
}
.profile-btn{
	width: 70%;
}
.driver-img-div{
	width: 11%;
	position: absolute;
}
.driver-detail-div{
	width: 88%;
	float: right;
	padding-right: 13px;
}
@media (min-width: 1371px) {
	 #id_welcome{
		width: 500px;
	}
	#replay_end_date{
		padding-top: 1em;
    	padding-bottom: 1em;
		    padding-left: -1px;
    padding-right: 0px
	}
	#search_list{
		font-size: 13px;
	}
	#replay_start_date{
		padding-top: 1em;
    	padding-bottom: 1em;
	    padding-left: -1px;
    padding-right: 0px
	}
	h4{
		font-size: 12px;
	}
	h5{
		font-size: 12px;
	}
	#support_no>h3{
		font-size: 20px;
	}
	.noti-width{
		width: 4% !important;
	}
	.left-wd{
		width: 4.333333% !important;
	}
	.call-no{
		width: 49.666667% !important;
	}
	.live-replay-w,.div-car-6-w,#lp-car-status{
		width: 16% !important;
	}
	.lp-car-details>h3{
		font-size: 20px;
	}
	#trv_name{
		font-size: 15px;
	}
	.class_veh_name{
		font-size: 20px;
	}
	.enter-bound>div>h5{
		font-size: 14px;
	}
	#terms-list-view{
		left: 188px;
	}
}
@media (min-width: 1601px) and (max-width: 1905px) {
	#terms-list-view{
		left: 205px;
	}
}
@media (min-width: 1024px) and (max-width: 1258px) {
	/* .gp-selection {
	    width: 46% !important;
	} */
	#gp-detailed-graph {
	    margin: 0px 0px 34px 30px;
	}
	.gp-graphs {
	    margin-right: 30px;
	}
	.gp-selection-child-1{
		font-size: 12px;
	}
	.gp-selection-child-3>button{
		font-size: 10px;
	}
	.gp-modes-child-2-1 {
	    width: 47%;
	}
	/* .gp-selection-child-1 {
	    margin-right: 8px;
	    margin-left: 8px;
	} */
	/* .gp-selection-child-2 {
	    margin-right: 8px;
    } */
    .gp-special>div>h5>strong{
    	font-size: 10px;
    }
    /* .gp-special>div>img{
    	margin-left: 0px !important;
    } */
	.gp-select-child-1>h5>strong,.gp-modes-child-1>h5>strong{
		font-size: 10px;
	}
	/* .gp-modes{
		width: 18% !important;
	} */
	/* .gp-select {
	    width: 22% !important;
	} */
	/* .gp-header {
	    padding: 0px 0px 0px 10px;
	} */
	.gp-select-child-1 {
	    width: 53%;
	}
	.gp-select-child-2 {
    	width: 44%;
	}
	/* .gp-special {
	    width: 12% !important;
	    margin-left: 6px;
	} */
}
@media (min-width: 1259px) and (max-width: 1356px) {
	/* .gp-selection {
	    width: 46% !important;
	} */
	#gp-detailed-graph {
	    margin: 0px 0px 34px 14px;
	}
	.click-graph-ev {
	    padding-left: 0% !important;
	}
	.veh-legend-wd-sp{
		width: 28.1% !important;
	}
	.vh-data-div-1{
		width: 19.6%;
	}
	.vh-data-div-2{
		width: 19.7%;
	}
	/* .selected-opt {
	    width: 60px !important;
	}
	.selection-time {
	    width: 7% !important;
	} */
	.gp-graphs {
	    margin-right: 15px;
	    margin-left: 5px;
	}
	.gp-selection-child-1{
		font-size: 12px;
	}
	.gp-selection-child-3>button{
		font-size: 10px;
	}
	.gp-modes-child-2-1 {
	    width: 48%;
	}
	.gp-select-child-1>h5>strong,.gp-modes-child-1>h5>strong{
		font-size: 12px;
	}
	/* .gp-modes{
		width: 18% !important;
	} */
	/* .gp-select {
	    width: 19% !important;
	} */
	/* .gp-header {
	    padding: 0px 0px 0px 28px;
	} */
	.gp-select-child-1 {
	    width: 55%;
	}
	.gp-select-child-2 {
    	width: 46%;
	}
	/* .gp-special {
	    width: 16% !important;
	} */
	/* .gp-selection-child-1 {
	    margin-right: 8px;
	    margin-left: 8px;
	} */
	/* .gp-selection-child-2 {
	    margin-right: 8px;
    } */
}
@media (min-width: 1371px) and (max-width: 1600px) {
	#terms-list-view{
		left: 199px;
	}
	.selection-time {
	    width: 11%;
	}
	#marker-info>div{
		width: 22.8%;
	}
	.click-graph-ev{
		padding-left: 2%;
	}
	.new-row-width{
		width: 90%;
	}
	.veh-legend-wd{
		width: 28%;
	}
	.veh-legend-wd-sp{
		width: 27.7%;
	}
	.count-box>h5{
		font-size: 11px;
	}
	.gp-graphs {
	    margin-right: 30px;
	}
	#gp-detailed-graph {
	    margin: 0px 0px 34px 30px;
	}
	.left-nav-opt{
		padding: 33px 17px 17px 17px;
	}
	#user-info>div>a>img {
	    width: 14%;
	}
	.profile-img{
		width: 9.5%;
	}
	.user_name{
		width: 20%;
	}
	.profile-btn{
		width: 68%;
	}
	.driver-img-div{
		width: 13%;
	}
	.driver-detail-div{
		width: 86%;
	}
	/* .gp-selection {
	    width: 46%;
	} */
	/* .gp-header {
	    padding: 0px 0px 0px 15px;
	} */
	.gp-select-child-1>h5>strong,.gp-modes-child-1>h5>strong{
		font-size: 12px;
	}
	/* .gp-modes{
		width: 21%;
	} */
	/* .gp-select {
	    width: 21%;
	} */
	/* .gp-selection-child-1 {
	    margin-right: 8px;
	    margin-left: 8px;
	} */
	/* .gp-selection-child-2 {
	    margin-right: 8px;
    } */
}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

#start-hrs-list,#end-hrs-list{
	height: 80px;
	overflow-y : auto;
	overflow-x: hidden;
}
.enter-bound-sm{
	padding-bottom: 7em;
	padding-top: 5em;
	padding-left: 3em;
}
#vehicle-location>h5>img{
	padding-top: 39px !important;
}
.notification-message{
		font-size: 20px;
	}
.single-row>div>h5{
	font-size: 14px;
}
.list-scroll{
		max-height: 58%;
	}
.grid-view{
	height: 73%;
	background-color: fcfcfc;
}
@media (max-height: 700px) {
 	 .list-scroll{
 		max-height: 45%;
 	}
 	.grid-view{
 		height: 61%;
 		background-color: fcfcfc;
 	}
 	#vla-body {
	    margin-top: 9em;
	}
	#vehicle-location>h5>img {
	    padding-top: 32px !important;
	}
	.create-bound-new{
		height: 77%;
		overflow: auto;
	}
}
	.gp-selection-child-1{
		font-size: 12px;
	}
	.gp-selection-child-3>button{
		font-size: 12px;
	}
@media (min-width: 1025px) and (max-width: 1370px) {
	.live-replay-w,.div-car-6-w,#lp-car-status{
		width: 21% !important;
	}
	.new-row-width{
		width: 90%;
	}
	.click-graph-ev {
	    padding-left: 3%;
	}
	.veh-legend-wd {
	    width: 28%;
	}
	.veh-legend-wd-sp{
		width: 27.7%;
	}
	.legend-div {
	    width: 22%;
	}
	.selected-opt,.selected-opt-1 {
	    width: 84px;
	}
	.count-box>h5{
		font-size: 9px;
	}
	.gp-td-4 {
	    width: 14%;
	}
	/* .gp-selection {
	    width: 44%;
	} */
	#gp-detailed-graph {
	    margin: 0px 0px 34px 30px;
	}
	.gp-td-1 {
	    width: 20%;
	}
	.gp-selection-child-1{
		font-size: 12px;
	}
	.gp-selection-child-3>button{
		font-size: 10px;
	}
	.gp-graphs {
	    margin-right: 30px;
	}
	/* .gp-header {
	    padding: 0px 0px 0px 18px;
	} */
	/* .gp-special {
	    width: 13%;
	} */
	/* .gp-selection-child-1 {
	    margin-right: 8px;
	    margin-left: 8px;
	} */
	/* .gp-selection-child-2 {
	    margin-right: 8px;
    } */
	.gp-select-child-1>h5>strong,.gp-modes-child-1>h5>strong{
		font-size: 12px;
	}
	/* .gp-modes{
		width: 21%;
	} */
	/* .gp-select {
	    width: 21%;
	} */
	.driver-img-div{
		width: 15%;
	}
	.noti-msg-1{
	    top: 39%;
	    right: 25%;
	}
	.page-1-opt{
		width: 95%;
	}
	.boundary-left-nav{
		width: 5%;
	}
	.driver-detail-div{
		width: 84%;
	}
	#destination-body{
	    margin-top: 11em;
	}
	#boundary-body {
	    margin-top: 7em;
	}
	#speed-body {
	    margin-top: 10em;
	}
	#terms-list-view{
		left: 183px;
	}
	#car-count{
		top: 16px;
	}
	.profile-img{
		width: 10.5%;
	}
	.user_name{
		width: 20%;
	}
	.profile-btn{
		width: 68%;
	}
	#vehicle-search-new{
		line-height: 1.9
	}
	#close-search-new{
		line-height: 1.9
	}
	#wel-div{
		bottom: 10.4%;
	}
	.enter-bound-sm{
		padding-bottom: 2em;
		padding-top: 2em;
	}
	.boundary-remove-css>h5{
		font-size: 15px;
	}
	/* #select-hrs>img,#select-minutes>img{
		width: 25%;
	} */
	.bound-drop-arrow {
	    margin-right: 0px;
	}
	.boundary-left-nav-opt>h5{
		font-size: 15px;
	}
	.graph-text{
		font-size: 13px;
	}
	.notification-message{
		font-size: 18px;
	}
	/* .driver-car-list>div>label {
	    font-size: 13px !important;
	} */
	.view-data>div>h3 {
	    font-size: 18px;
	}
	.shift-time {
	    padding: 1em 0em 0em 1em;
	}
	.class_veh_name{
		font-size: 20px;
	}
	/* .profile-btn {
	    padding-top: 4em;
	} */
	h3{
		font-size: 18px;
	}
	h5{
		font-size: 10px;
	}
	h4{
		font-size: 11px;
	}
	.down-report>div>div>h3 {
	    font-size: 15px;
	}
	#report-select-vehicle>input{
		font-size: 11px;
	}
	#support_no>h3{
		font-size: 18px;
	}
	.noti-width,.left-wd{
		width: 5.7% !important;
	}
	.left-wd{
		width: 4.333333% !important;
	}
	.call-no{
		width: 47.666667% !important;
	}
	#id_welcome{
		bottom: 10%;
		width: 38vw;
	}
	#div3>div>h4{
		font-size: 14px;
	}
	.wel-text{
		width: 64% !important;
		font-size: 10px;
	}
	.con-div{
		width: 32% !important;
	}
	.noti-text>p{
		font-size: 14px;
	}
	.base-header>div>div>h3 {
	    font-size: 22px ;
	}
	/* .left-nav-opt {
	    margin: 1.2em 1em 1.2em 1em !important;
	} */
	.lp-car-details>h3{
		font-size: 21px;
	}
	.lp-car-details>h5{
		font-size: 11px;
	}
	#div-car-6>div>h4{
		font-size: 14px;
	}
	#live_div>div>h4{
		font-size: 14px;
	}
	#replay_div>div>h4{
		font-size: 14px;
	}
	#live_track_header{
		font-size: 12px;
	}
	/* .show-car-list>h4{
		font-size: 12px;
	} */
	.lp-car-detail>div>h3{
		font-size: 13px;
	}
	.lp-car-detail>div>h4{
		font-size: 12px;
	}
	.conn-car-detail>div>h3{
		font-size: 16px;
	}
	.conn-car-detail>div>h4{
		font-size: 13px;
	}
	.idling-car-detail>div>h3{
		font-size: 16px;
	}
	.idling-car-detail>div>h4{
		font-size: 13px;
	}
	.parked-car-detail>div>h3{
		font-size: 16px;
	}
	.parked-car-detail>div>h4{
		font-size: 13px;
	}
	.lt-car-detail>div>h3{
		font-size: 16px;
	}
	.lt-car-detail>div>h4{
		font-size: 13px;
	}
	#ongoing-div>div>h4{
		font-size: 14px;
	}
	#connection-lost>div>h4{
		font-size: 14px;
	}
	#idling-div>div>h4{
		font-size: 14px;
	}
	#parked-div>div>h4{
		font-size: 14px;
	}
	/* .lp-edit-car-dt>div>div>h5{
		font-size: 12px !important;
	}
	.idling-edit-car-dt>div>div>h5{
		font-size: 12px !important;
	}
	.parked-edit-car-dt>div>div>h5{
		font-size: 12px !important;
	} */
	#replay_header{
		font-size: 14px;
	}
	#replay_start_date{
		padding-top: .8em;
    	padding-bottom: .8em;

	}
	#replay_start_date>h4{
		font-size: 13px;
	}
	#replay_end_date>h4{
		font-size: 13px;
	}
	#replay_end_date{
		padding-top: .8em;
    	padding-bottom: .8em;

	}
	.total-dist>h4{
		font-size: 15px;
	}
	.user_name {
	    padding-top: 0em;
	}
	.enter-bound>div{
		padding-top: 1.5em;
    	padding-bottom: 1.5em;
	}
	#reportrange{
		font-size: 12px;
	}
	.left-nav-opt{
		padding: 22px 17px 17px 17px;
	}
	#support_no>h3>span{
		font-size: 14px;
	}
	#bell-div>div{
		padding-left: 8px;
	}
	#user-info>div>a>img {
	    width: 14%;
	}
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
#support_no>h3>span{
	font-size: 15px;
}
.bound-switch{
	padding-left: 4em;
}
.gp-selection-child-1{
	cursor: pointer;
}
.cal-pos{
	top: -29px;
}
@media (min-width: 768px) and (max-width: 1024px) {
	/* .left-nav-opt {
	    margin: 1.2em 1em 1.2em 1em !important;
	}	 */
	.enter-bound>div{
		padding-top: 1em;
    	padding-bottom: 1em;
	}
	.gp-ignition-count-child-2-2>div>div {
	    padding: 3px;
	}
	.cal-image{
		top: -12px;
	}
	.cal-pos{
		top: -12px;
	}
	#first-vehicle-div>div>div {
	    padding: 3px;
	}
	.shift-time {
	    padding: 1em 0em 0em 3em;
	}
	.click-graph-ev {
	    padding-left: 0%;
	}
	.vh-data-div-1{
		width: 20.6%;
	}
	.vh-data-div-2{
		width: 20.7%;
	}
	.legend-div {
	    width: 23%;
	}
	.gp-ignition-count-child-2-3 {
	    top: -15px;
	}
	/* .cal-image>div>img{
		width: 84%;
	}
	.cal-image>div{
		left: 17px;
	} */
	.selection-time {
	    width: 8%;
	}
	.selected-opt,.selected-opt-1{
		padding: 3px 0px 3px 4px;
		width: 72px;
		background-color: #ffffff !important;
		border: 1px solid #000000;
	}
	.veh-legend-wd{
		width: 22%;
	}
	.arrow-div>.glyphicon-triangle-top,.arrow-div-1>.glyphicon-triangle-top{
		color: #ffffff;
		font-size: 10px;
		margin-top: 0px;
	}
	.gp-ignition-count-child-2-3 {
	    padding: 4px;
	}
	.count-box-1{
		width: 23.6% !important;
	}
	.count-box-3{
		width: 43% !important;
	}
	.arrow-div,.arrow-div-1{
		height: 26px;
		padding: 3px;
		background-color: #5E5F68;
		position: absolute;
		top: 0px;
	}
	.new-row-width{
		width: 87%;
	}
	.count-box>h5{
		font-size: 8px;
	}
	.first-vehicle{
		height: 20px;
		width: 20px;
	}
	.second-vehicle{
		height: 20px;
		width: 20px;
	}
	.third-vehicle{
		height: 20px;
		width: 20px;
	}
	.fourth-vehicle{
		height: 20px;
		width: 20px;
	}
	.driver-speed-first-vehicle,.driver-acc-first-vehicle,
	.driver-acc-second-vehicle,.driver-acc-third-vehicle,
	.driver-acc-fourth-vehicle,.driver-brake-first-vehicle,
	.driver-brake-second-vehicle,.driver-brake-third-vehicle,
	.driver-brake-fourth-vehicle,.driver-speed-second-vehicle,
	.driver-speed-third-vehicle,.driver-speed-fourth-vehicle {
	    height: 20px;
	    width: 20px;
	}
	.gp-ignition-count-child-2-2>div>div>div>h5{
		font-size: 8px;
	}
	/* .count-box{
		margin-left: 4px;
		margin-right: 4px;
	} */
	.gp-td-2{
		width: 19%;
	}
	.left-nav-opt {
	    padding: 22px 17px 17px 17px;
	}
	.gp-td-4 {
	    width: 14%;
	}
	.gp-td-1 {
	    width: 23%;
	}
	.page-1-opt{
		width: 94%;
	}
	.bound-switch{
		padding-left: 1em;
	}
	.boundary-left-nav{
		width: 6%;
	}
	.driver-img-div{
		width: 21%;
	}
	.driver-detail-div{
		width: 78%;
	}
	.profile-img{
		width: 15%;
	}
	.user_name{
		width: 20%;
	}
	.profile-btn{
		width: 64%;
	}
	#support_no>h3>span{
		font-size: 14px;
	}
	#car-count{
		top: 16px;
	}
	#vehicle-search-new{
		line-height: 1.9
	}
	#close-search-new{
		line-height: 1.9
	}
	#terms-list-view{
		left: 183px;
	}
	.create-footer-text>h4 {
	    font-size: 14px;
	}
	#setting-vehicles>input {
	    font-size: 15px;
	}
	.profile-row{
		padding: 3em 2em 0em 2em;
	}
	.notification-message{
		font-size: 18px;
	}
	#reportrange{
		font-size: 12px;
	}
	#report-select-vehicle>input,#special-report-select-vehicle>input{
		font-size: 13px;
	}
	.vehicle-text>h3{
		font-size: 14px !important;
	}
	/* .profile-btn {
	    padding-top: 4em;
	} */
	.user_name {
	    padding-top: 0em;
	}
	.total-dist>h4{
		font-size: 15px;
	}
	/* .car-data{
		padding: 1em;
	} */
	#id_welcome{
		bottom: 10%;
		width: 46vw;
	}
	#div3>div>h4{
		font-size: 14px;
	}
	.noti-text>p{
		font-size: 14px;
	}
	.base-header>div>div>h3 {
	    font-size: 17px ;
	}
	.wel-text{
		width: 64% !important;
		font-size: 10px;
	}
	.con-div{
		width: 32% !important;
	}
	.user_name>h1{
		font-size: 3em;
	}
	#configure-vehicle{
		font-size: 13px;
	}
	.lp-car-details>h3{
		font-size: 21px;
	}
	.lp-car-details>h5{
		font-size: 11px;
	}
	#div-car-6>div>h4{
		font-size: 14px;
	}
	#live_div>div>h4{
		font-size: 14px;
	}
	#replay_div>div>h4{
		font-size: 14px;
	}
	#live_track_header{
		font-size: 12px;
	}
	.show-car-list, .header-img .search-img{
		cursor:pointer;
	}
	/* .show-car-list>h4{
		font-size: 10px;
	} */
	.lp-car-detail>div>h3{
		font-size: 13px;
	}
	.lp-car-detail>div>h4{
		font-size: 12px;
	}
	.conn-car-detail>div>h3{
		font-size: 16px;
	}
	.conn-car-detail>div>h4{
		font-size: 13px;
	}
	.idling-car-detail>div>h3{
		font-size: 16px;
	}
	.idling-car-detail>div>h4{
		font-size: 13px;
	}
	.parked-car-detail>div>h3{
		font-size: 16px;
	}
	.parked-car-detail>div>h4{
		font-size: 13px;
	}
	.lt-car-detail>div>h3{
		font-size: 16px;
	}
	.lt-car-detail>div>h4{
		font-size: 13px;
	}
	#ongoing-div>div>h4{
		font-size: 14px;
	}
	#connection-lost>div>h4{
		font-size: 14px;
	}
	#idling-div>div>h4{
		font-size: 14px;
	}
	#parked-div>div>h4{
		font-size: 14px;
	}
	/* .lp-edit-car-dt>div>div>h5{
		font-size: 12px !important;
	}
	.idling-edit-car-dt>div>div>h5{
		font-size: 12px !important;
	}
	.parked-edit-car-dt>div>div>h5{
		font-size: 12px !important;
	} */
	#replay_header{
		font-size: 14px;
	}
	#replay_start_date{
		padding-top: .8em;
    	padding-bottom: .8em;

	}
	#replay_start_date>h4{
		font-size: 14px;
	}
	#replay_end_date>h4{
		font-size: 14px;
	}
	#replay_end_date{
		padding-top: .8em;
    	padding-bottom: .8em;

	}
	.view-data>div>h3 {
	    font-size: 16px;
	}
	h3{
		font-size: 18px;
	}
	h5{
		font-size: 11px;
	}
	h4{
		font-size: 13px;
	}
	.car-data>div>h2 {
	    font-size: 17px;
	}
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {



}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {



}
/**CSS FOR THE RING**/

.glyphicon-ring {
  box-shadow: 0px 2px 6px 3px #e0e0e0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid white;
  color: white;
  display: inline-table;
  text-align: center;
}
/**CSS FOR ICON WITH NO BACKGROUND COLOR**/

.glyphicon-ring .glyphicon-bordered {
  font-size: 20px;
  vertical-align: middle;
  display: table-cell;
}
/**WITH AN ADDED BACKGROUND COLOR**/

.glyphicon-white {
  background: white;
  color: black;
  border: 4px solid black;
}
.glyphicon-teal {
  background: teal;
  color: orange;
}
.glyphicon-red {
  background: red;
}
.rotated{
    transform: rotate(360deg);
    transition-duration:2s;
}
.legend_opacity{
  opacity:0.3;
}
.canvasjs-chart-toolbar> button{
  display: none !important;
}

#gp-vusage-count .canvasjs-chart-toolbar> button{
  display: none !important;
}
.home-img-div{
	position: absolute;
	right: 35px;
	z-index: 9;
}

.faded{
	opacity: 0.6;
}
.float-btn {
	outline: 0;
    background-color: #ffffff;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    outline: 0;
    box-shadow: 0px 0px 9px 1px #bfbfbf;
}
.header-div>div{
	margin-left: 13px;
	margin-right: 13px;
}
.share-close{
	position: fixed;
    z-index: 9;
    left: 7rem;
    top: 6.5rem;
    padding: 2px 20px;
    background-color: #ffffff;
    box-shadow: 0px 0px 2px 3px #b3b3b3;
    border: 1px solid;
}
h5{
	font-size: 11px;
}
.car-name-wd{
	width: 45%;
}
#wel-div{
  	display: block;
  }
  #floating-btn{
  	display: block;
  }
  .m-footer-div{
  	display: none;
  }
#replay-route-new{
	margin-left: -1.2px;
}
#seekbar-div{
	display: none;
}
.close-page>img{
	width: 30px;
}
@media (min-width: 320px) and (max-width: 767px) {
	 #wel-div{
	 	display: none;
	 }
	 .close-page>img{
		width: 25px;
	}
	.user-name-div>h6{
		font-size: 0.8rem;
	}
	 .m-footer-div{
	 	display: block;
	 }
	 .m-header-div{
	 	display: block;
	 }
	 #floating-btn{
	 	display: none;
	 }
	 .m-footer-div{
	position: fixed;
	z-index: 9;
	bottom: 0px;
	width: 100%;
	background: #ffffff;
	padding: 10px 25px;
	 }
	 .share-close{
	left: 8rem;
	top: 5.7rem;
	 }
	 .header-div>div {
	    margin-left: 5px;
	    margin-right: 5px;
	}
}

.prunecluster {
	font-size: 12px;
	border-radius: 20px;
	transition: all 0.3s linear;
}
.leaflet-marker-icon.prunecluster-anim,
.leaflet-marker-shadow.prunecluster-anim,
.leaflet-markercluster-icon.prunecluster-anim {
	transition: all 0.3s linear;
}

.leaflet-zoom-anim .leaflet-zoom-animated.leaflet-marker-icon,
.leaflet-zoom-anim .leaflet-zoom-animated.leaflet-marker-shadow,
.leaflet-zoom-anim .leaflet-zoom-animated.leaflet-markercluster-icon {
    transition: transform 0.25s cubic-bezier(0,0,0.25,1);
}
.prunecluster div {
	width: 30px;
	height: 30px;
	text-align: center;
	margin-left: 5px;
	margin-top: 5px;
	border-radius: 50%;
}
.prunecluster div span {
	line-height: 30px;
}

.prunecluster-small {
	background-color: #b5e28c;
	background-color: rgba(181, 226, 140, 0.6);
}

.prunecluster-small div {
	width: 28px;
	height: 28px;
	background-color: #6ecc39;
	background-color: rgba(110, 204, 57, 0.6);
}

.prunecluster-small div span {
	line-height: 28px;
}

.prunecluster-medium {
	background-color: #f1d357;
	background-color: rgba(241, 211, 87, 0.6);
}

.prunecluster-medium div {
	background-color: #f0c20c;
	background-color: rgba(240, 194, 12, 0.6);
}

.prunecluster-large {
	background-color: #fd9c73;
	background-color: rgba(253, 156, 115, 0.6);
}

.prunecluster-large div {
	width: 34px;
	height: 34px;
	background-color: #f18017;
	background-color: rgba(241, 128, 23, 0.6);
}

.prunecluster-large div span {
	line-height: 34px;
}
.header-div{
	position: relative !important;
}
.data-div{
	margin-top: 0px !important
}
.logout-img{
	display: none;
}
.close-page{
	display: block;
}
.map-control,.search_dv.leaflet-control{
	display: none!important;
}
#floating-btn,#wel-div{
	z-index: 9999;
}
#floating-btn{
	border-radius: 50%;
	background: #fff;
}
