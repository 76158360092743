.header-logo{
  max-width:270px;
  height:auto;
  position: relative;
  display:block;
  margin:0 auto;
}
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent!important;
}
.float-btn{
border-radius: 50%;
}
.w100{
  width:100%;
  height:100%;
  display:block;
}
.color{
color:#EE7628!important;
}
.marr-20{
margin-right: 20px;
}
.green{
border:1px solid green!important;
}
.blue{
border:1px solid blue!important;
}
.mr-30{
margin-right:30px!important;
}
.weekly,.monthly{
width:250px!important;
}
/* .red1{
border:3px solid green!important;
}
.red2{
border:2px solid blue!important;
} */
.border{
border:1px solid red!important;
}
.map-btn{
padding: 5px;
background-color: #fff;
border: none;
}
.pos-rel{
position: relative;
}
/* #history_btn{
border:2px solid red!important;
} */
.order-no{
width:1600px!important;
margin-top: 8px!important;
}
.common_message_class{
font-weight: bold;
}

.styles_closeButton__20ID4{
top: -10px;
right: -5px;
}

.loading-icon{
border-radius: 50%;
width: 200px;
height: 200px;
left: 45%;
bottom: 48%;
z-index: 9999;
align-items: center;
position: absolute;
background-image: url("./assets/images/Loading_Elli.gif");
}
.icon{
display: block;
float: left;
width:100px;
height: 100px;
}
#loading-image{
position: fixed;
top: 0px;
left: 0px;
z-index: 9999999;
width: 100%;
height:100%;
background-color: #000;
opacity: 0.3;
}


.navbar-default{
box-shadow: 0px 4px 7px #cecbcb;
}
.react-tabs__tab--selected button{
color:white !important;
}
.border-right{
  border-right: 1px solid #dad7d7;
  padding: 6px 20px;
  margin-right: 16px;
}
.float-align{
float: left !important;
  padding-left: 0 !important;
}

#map-btn{
color: #fff!important;
text-decoration: none!important;
}

.cursor-pointer{
cursor: pointer;
}
.login-btn{
position: absolute;
left: 50%;
top: 50%;
}

.container{
padding-left: 0px!important;
padding-right:0px!important;
}

.styles_closeIcon__1QwbI {
  margin-top: 0px!important;
  margin-right: 0px!important;
  background: #b4b4b4!important;
  border-radius: 50%!important;
}

.DateInput_input {
  font-size: 13px!important;
  color: #aba7aa!important;
  background-color: #fff!important;
  width: 100%;
  font-weight: bold!important;
  padding: 0px!important;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.DateInput {
  margin: 0;
  padding: 0px 15px!important;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 95px!important;
  vertical-align: middle;
}

.DateInput_input__focused{
border-bottom: transparent!important;
}

.ul-list{
width:300px;
list-style-type: none;
position: absolute;
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc{
box-shadow: none!important;
}

.ReactTable .rt-thead .rt-th.-sort-desc{
box-shadow: none!important;
}
.loader-class{
border:1px solid red!important;
background-color: lightgreen!important;
}
.export-btn{
cursor: pointer;
}
.f-right{
float:right;
}

.color-orange{
  color: #ec7018;
}

.red{
color: red;
font-size: 12px;
}

.orange{
color: #fff;
border: 1px solid;
font-size: 25px;
padding-top: 5px;
padding-bottom: 5px;
border-radius: 5px;
background: #ee7527;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before{
display: none!important;
pointer-events: none!important;
cursor: default!important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before{
display: none!important;
pointer-events: none!important;
cursor: default!important;
}

.DayPickerKeyboardShortcuts_buttonReset:active{
pointer-events: none;
cursor: default!important;
}

.pt-35{
padding-top:35px;
}

.search-icon{
margin-top:8px;
margin-right: 10px!important;
cursor: pointer;
}

.textarea-style{
background-color: #ddd;
color: gray;
font-size: 13px;
resize: none;
border:none;
}
.gray{
background-color: #ddd;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #b5b7b8!important;
}

.table-bordered td, .table-bordered th {
  border: 1px solid #b5b7b8!important;
}

.styles_closeIcon__1QwbI{
width: 20px!important;
height: 20px!important;
}

.fs-14{
  font-size: 14px;
}
.fs-21{
font-size: 21px;
}
.fc-red{
color:red;
}
.btn-weekly,.btn-monthly,.btn-all,.default{
  margin: 0px 14px;
  padding: 5px 9px !important;
  font-size: 13px !important;
  color: #aba8a8 !important;
  font-weight: bold !important;
  cursor: pointer;
}
.default{
width:200px;
}
.gray-shadow{
  box-shadow: 0px 2px 2px 3px #ececec;
  border-radius: 4px;
}

.custom-select{
  width: 207px !important;
  padding: 5px;
  background: transparent;
  border: none !important;
  box-shadow: 0px 2px 2px 3px #ececec;
  border-radius: 4px;
  margin: 0px 10px;
  font-size: 13px !important;
  color: #aba8a8 !important;
  font-weight: bold !important;
  background: none !important;
}
.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid transparent!important;
}
/* .submit-btn{
font-size: 13.5px!important;
width:100%;
} */

.buttons{
float:left;
display:inline;
}
.div_btn{
border:1px solid blue;
float:left;
display:inline;
}

.line-height-none{
  line-height: 0px !important;
}

.custom-serach-input{
  width: 300px !important;
  border: none !important;
  font-size: 13px !important;
  color: #aba8a8 !important;
  font-weight: bold !important;
}
.search-input{
  box-shadow: 0px 2px 2px 3px #ececec;
  border-radius: 4px;
}
.dis-inline-block{
  display: inline-block;
}
.btn-submit{
  font-size: 13px !important;
  background: #37383a !important;
  color: white !important;
  font-weight: bold;
  padding: 6px 27px !important;
  border-radius: 5px;
  margin-left: 20px;
}
::placeholder{
  color: #aba8a8 !important;
  font-size: 12px;
}
.period-color{
  font-size: 13px !important;
  color: #aba8a8 !important;
  font-weight: bold !important;
  margin-left: 15px;
  padding-right: 15px;
}
.weekly,.monthly,.all{
  background-color: #fff;
  border: none;
  /* width: 200px; */
  padding: 11px;
  color: #aba7aa;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
}
.all{
width:252px!important;
}
.react-tabs__tab-panel--selected{
margin-right: 20px!important;
}
.float-align{
margin-right: 5px!important;
}
.pd-3{
padding: 0.2rem!important;
}
.CalendarMonth_caption{
color: gray;
}
.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #282828c4;
  background: #fff;
}
.all{
background-color: #fff;
border: none;
/* width: 235px!important; */
padding: 0px!important;
color: #aba7aa;
font-size: 13px;
font-weight: bold;
}

.print-btn{
margin-bottom: 4px;
float: right;
margin-right:0px!important;
}
.order-no span{
font-size: 13px;
font-weight: bold;
}

#test-table-xls-button{
background-color: #343940;
color: #fff;
border: none;
font-size: 12px;
padding: 6px;
border-radius: 4px;
margin-bottom: 4px;
float: right;
margin-right:8px;
}

#table-to-xls{
font-size: 13px;
}

.textarea-field{
border:1px solid #d7d7d7!important;
min-height: 30px;
background-color: #f3f3f3;
}


.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover{
background-color: #ff9446!important;
border: 1px double lightgray;
color: #fff!important;
}

.CalendarDay__selected_span{
background-color: #ff9446!important;
border: 1px double lightgray!important;
color: #fff!important;
}

.CalendarDay__hovered_span,.CalendarDay__hovered_span:hover{
background-color: #ff9446!important;
border: 1px double lightgray!important;
color: #fff!important;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover{
background-color: #ff9446!important;
border: 1px double lightgray!important;
}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover,.CalendarDay__hovered_span:active{
background-color: #ff9446!important;
border: 1px double lightgray!important;
}

.btn_weekly,.btn_monthly,.btn_all{
background: transparent;
border: none;
font-size: 13px;
font-weight: bold;
color: #aba7aa;
padding: 6px 18px;
border-radius: 5px;
border:none !important;
cursor: pointer;
}

.btn_weekly:hover,.btn_monthly:hover,.btn_all:hover{
background-color: #EE7628;
color: #fff!important;
transition: 0.5s;
border:none !important;
}
.btn_weekly:active,.btn_monthly:active,.btn_all:active{
color: white !important;
}
button:focus {

outline: none !important;
}
button:active{
color: white !important;
}
.pl-0{
padding-left: 0px;
}
.list-inline-item:not(:last-child) {
  margin-right: 1rem;
}
.bgbody{
background: linear-gradient(#ED9D2B,#EE4732);
height: 100vh;
min-height: 100%;
}
 
 .w-100{
width: 100%;
margin-bottom: 10px;
} 
.btn-proceed{
color: #ffffff;
background-color: rgb(183, 61, 24) !important;
border:none;
}
.mb-30{
margin-bottom: 30px!important;
}

.react-tabs__tab-list{
float: left;
}
.react-tabs__tab-list{
/* border:none; */
box-shadow: 0px 2px 2px 3px #ececec;
border-radius: 4px;
border-bottom: 1px solid transparent!important;
}
.period-text{
padding-top: 10px;
font-size: 13px;
font-weight: bold;
color: #aba6aa;
padding-left: 20px;
padding-right: 10px;
}
.react-tabs__tab--selected{
background: #EE7628!important;
color: #fff!important;
border:1px solid #fff!important;
border-radius: 6px !important;
}
.react-tabs__tab-panel--selected{
display: inline-flex!important;
border-color: transparent;
color: black;
border-radius: 5px 5px 5px 5px;
}
.react-tabs__tab{
padding: 2px 10px!important;
}
.pd-2{
padding:2px!important;
}
.btn-dark{
border:none!important;
font-size: 12px !important;
margin-right: 10px;
}
.text-login{
font-size: 25px;
color: #6b6b6b;
padding: 40px 0 30px;
}

.custom-input{
border-radius: 0 !important;
  border: 1px solid #b1b1b1 !important;
  margin-bottom: 10px !important;
}

.plr-20{
padding: 0px 20px;
}
/***************** login css start ******************/

.bgbody{
background: linear-gradient(#ED9D2B,#EE4732);
height: 100vh;
min-height: 100%;
}

.content-padd{
box-shadow: 1px 1px 19px 1px gray;
border-radius: 7px;
background: #fff;
height: 415px;
width: 500px;
padding: 45px 55px;
margin: 0px 31.5%;
}

.w85{
width: 85%;
}

.text-login{
font-size: 25px;
color: #6b6b6b;
padding: 40px 0px 30px;
}

.custom-input{
border-radius: 0px;
border: 1px solid #b1b1b1;
}
#resend_otp {
  text-decoration: underline;
  text-align: right;
  margin-left: 244px;
}
.btn-proceed{
border-radius: 0px !important;

color:black !important;
padding: 7px 0px;
font-size: 17px;
font-family: "Helvetica";
background: -webkit-linear-gradient(to right, #F2C94C, #F2994A);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #F2C94C, #F2994A);
}
.h100{
height: 100vh !important;
min-height: 100% !important;
}
.align-center{
align-items: center!important;
}
.justify-center {
justify-content: center!important;
}
.ui-dialog{
border:1px solid red!important;
height: auto!important;
width: 100%!important;
transform: translate(0px, 0px)!important;
}
/* datatable css */
.ReactTable .rt-thead .rt-resizable-header-content {

font-size: 13px;
font-weight: bold;
padding: 10px 0px;
}
.ReactTable .rt-td {
font-size: 12px;
font-weight: bold;
color:gray;
}

@media only screen and (max-width:1433px){
.resp-input-group{
  width:106%!important;
}
}


@media only screen and (max-width:1500px){
.btn_weekly{
  background: transparent;
  border: none;
  font-size: 13px;
  font-weight: bold;
  color: #aba7aa;
  border-radius: 5px;
  border:none !important;
  width: 30px;
  padding: 4px 4px;
  cursor: pointer;
}
.btn_monthly{
  background: transparent;
  border: none;
  font-size: 13px;
  font-weight: bold;
  color: #aba7aa;
  border-radius: 5px;
  border:none !important;
  width: 30px;
  padding: 4px 0px;
  cursor: pointer;
}
.btn_all{
  background: transparent;
  border: none;
  font-size: 13px;
  font-weight: bold;
  color: #aba7aa;
  border-radius: 5px;
  border:none !important;
  width: 30px;
  padding-left: 15px;
  cursor: pointer;
}
.react-tabs__tab{
  /* padding: 2px 10px!important; */
  width: 75px!important;
}
}


@media only screen and (max-width:1367px){
.resp-input-group{
  width:107%!important;
}
}

@media only screen and (max-width:1280px){
.react-tabs__tab {
    width: 70px!important;
}
.custom-serach-input {
  width: 175px !important;
}
.resp-input-group {
  width: 99%!important;
}
.w134{
    width: 134px !important;
}
.all{
width: 210px!important;
}
.DateInput {

padding: 0px 7px!important;
width: 85px!important;

}
}

.period-text1{
padding-top: 10px;
font-size: 15px;
font-weight: bold;
color: #9b8b98;
padding-left: 20px;
padding-right: 10px;
}

.calendar_parent {
position: absolute;
width: 16%;
background-color: #ffffff;
z-index: 999;
box-shadow: 0px 0px 10px 2px #f3f3f3;
padding: 10px;
border-radius: 10px;
display: none;
}

.vd_journey_icon{
float: right;
color: #F0762B;
}

.fade-out {
  opacity: 0.5; /* Adjust the opacity to control the degree of fading */
  pointer-events: none; /* Disable interaction with the faded input field */
}

.time_div{
padding: 10px;
background-color: #e4e4e4;
border-radius: 10px;
}
.time_div>p{
margin: 0px;
font-weight: 700;
color: #aba6aa;
}

.summary_report_filters{
background-color: #e4e4e4;
padding: 10px;
border-radius: 10px;
height: 40px;
margin-top: 5px;
@include BSC_semi_bold16;
}

.all_region_dd{
position: absolute;
display: none;    
}

.dropdown_parent{
background-color: #eeeeee;
padding: 10px;
border-radius: 0px 0px 10px 10px;
box-shadow: 0px 0px 8px 2px #f3f3f3;
max-height: 10em;
overflow: auto;
}


.custom-serach-input1{
  width: 250px !important;
  border: none !important;
  font-size: 15px !important;
  color: black !important;
  font-weight: bold !important;
}

.journey_detail_div{
margin-right: 100px;
margin-left: 100px;
height: 100%;
background-color: #ffffff;    
border-radius: 10px;
box-shadow: 0px 0px 10px 2px #f3f3f3;
border: 1px solid #f3f3f3;
padding: 30px;
}

.vd_journey_icon{
float: right;
color: #F0762B;
}

.input_div{
position: relative;
}
.input_div>img{
position: absolute;
top: 10px;
right: 10px;
}

.journey_input{
width: 100%;
border: none;
background-color: #e4e4e4;
padding: 10px;
padding-right: 35px;
/* border-radius: 10px; */
outline: 0;
height: 40px;
@include BSC_semi_bold16;
}

.calender_icon{
float: right;
color: #F0762B;
}


/* new edit */


.login_parent{
background-color: #222222;
position: relative;
}
.login_header{
color: #ffffff;
@include BSC_medium;
font-weight: bold;
}
.textbox_suggestion{
color: #c5c5c5;
@include work_sans_regular16;
display: none;
}
.login_details_div{
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);    
width: 366px;
z-index: 99;
}
.show_password{
position: absolute;
cursor: pointer;
bottom: 215px;
right: 0;
}
.switch_to{
@include BSC_semi_bold16;
font-size: 14px;
color: #f4811f;
bottom: 113px;
right: 0;
position: absolute;    
cursor: pointer;
}
.login_details_div>input,
.login_details_div>button,
.login_details_div>p{
margin-bottom: 2.5em;
}
.login_details_div>input{
@include BSC_medium;
font-weight: bold;
font-size: 26px;
padding: 25px 0px;
border: none;
box-shadow: none;
border-bottom: 1px solid #979797;
border-radius: 0px;
color: #c5c5c5;
background: transparent;
}
input:-webkit-autofill {
background-color: red !important;
}
.login_details_div>input::-webkit-inner-spin-button, 
.login_details_div>input::-webkit-outer-spin-button { 
-webkit-appearance: none; 
margin: 0; 
}
.login_details_div>input:focus{
border: none;
box-shadow: none;
border-bottom: 1px solid #828282;
border-radius: 0px;
}
.sign_in_btn{    
float: none;
display: block;
margin: auto;
padding-left: 35px;
padding-right: 35px;
}
.sign_in_info{
text-align: center;
color: $orange;
font-size: 20px;
}
.login_bg_img{
position: absolute;
bottom: 0;
right: 0;
width: 40%;
height: 60%;
}
.form-control[disabled]{
background-color: transparent !important;
}
.customer_care{
position: absolute;
bottom: 0;
right: 0;
margin-right: 25px;
color: #ffffff;
}

.user_profile {
  @include shadow;
  border-radius: 5px;
  
  border: 2px solid rgb(194, 142, 142);
  background-color:#ffffff ;
  /* width: 70%; */
  box-shadow: 0px 0px 10px 5px #656565;
  padding: 20px;
  margin: 50px;
}


.api_parent{
    margin-top: 40px ;
} 

.grid-container {
  display: grid;
  grid-template-columns: auto auto;  
}
.clickable-text{
  color: #F0762B;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}
/* .p_header{    
  text-transform: uppercase;    
  font-size: 30px;
  font-size: 100;
  @include BSC_extra_bold18;
} */
 


.help_text{
  font-size: 12px;
  color: rgb(220, 53, 69);
  margin-bottom: 0px;
  margin-top: 5px;
}



.p_header{    
  text-transform: uppercase; 
  font-family: "Roboto",sans-serif;   
  font-weight: 200;
  font-size: 20px;
  font-style:bold;
  /* @include BSC_extra_bold18 */
}

.api_image{
  width: 100%;
  display: block;
  margin: auto;
}
.addtional_detail_input{
  border: 1px solid black;
  
  background-color: #eeeeee;
  outline: none;
  color: #000000;
  padding: 10px;
  border-radius: 10px;
}
.api_image{
  width: 100%;
  display: block;
  margin: auto;
}
.renew_modal_body {
  padding: 60px;
}
.modal_body {
  background: #ffffff;
  width: 40%;
  margin: auto;
  margin-top: 8%;
  margin-bottom: 8%;
  padding: 20px 20px 20px 40px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 5px #656565;
  position: relative;
}

.modal_parent {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  background: #00000075;
  z-index: 99999;
  display: none;
  overflow: auto;
}

.clickable-text{
  color: #F0762B;
  text-decoration:none;
  font-weight: 700;
  font-size: 22px;
  cursor: pointer;
  margin-left: 50px;

}

.clickable-text p{
  color: #F0762B;
  text-decoration: none;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
  margin-left: 50px;

}

.clickable-text p:hover{
  color: rgb(167, 167, 238);
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
  margin-left: 50px;

}
#api_key{
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}

.backbtn{
  /* background-color: #e9d7d7; */
  
   color: #000;
   font-family: "Barlow Condensed";
  text-decoration:none;
  font-weight: 100;
  width: 130px;
  padding: 5px;
  font-size: 30px;
  cursor: pointer;
  margin: 50px 0 0 50px;

}
.backbtn link span{
    color:red;

  text-decoration: none;

}

.API_header{
  margin: 0 0 0 50px;
  font-family: "Barlow Condensed";
  text-transform: uppercase;    
  font-weight: 600;
  font-size:36px;
  font-style:bold;

}

