.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.not_found_parent{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.not_found_child{
    box-shadow: 0px 0px 5px 2px #cccccc;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cssdropdown, #cssdropdown ul {
  list-style: none;
}

  #cssdropdown, #cssdropdown * {
  padding: 0;
  margin: 0;
}

  /* Head links */
#profile_menu {  
  float: left;
  text-align: center;
  position:relative;
}

  /* Child lists and links */




#profile_menu ul li Link span{
  padding: 5px;
  height: 17px;
  font-size:40px;
  cursor:pointer;
  width: 40px;
  /* background-color: #333; */
  color: white;
}
#profile_menu ul li Link span:hover {
  background-color: #333;
  /* color: rgb(206, 5, 5); */
  text-decoration: none;
  

}



  /* Pretty styling */
#cssdropdown a {
  color: white;
}
#cssdropdown ul li a:hover {
  text-decoration: none;
}
/* #profile_menu {
  background-color: rgb(0,0,0);
} */
#profile_menu ul {
  background-color: rgb(204, 198, 198);
  /* background-color: rgb(194, 18, 18); */
  background-position: bottom;
  /* background-color: rgb(231, 47, 47); */
  padding-bottom: 10px;
 
  width: 180px;
  /* border: 1px solid rgb(223, 14, 14); */
  /* box-shadow: 0px 2px 2px 3px #ececec; */
  border-radius: 4px;
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: none; 
  text-align: center;
  position:absolute;
  top:100%;
  border-radius: 0.2;
}

#profile_menu:hover ul {
  display: block;
}


.closebutton{
  display: none !important;
}

h4.modal-title{
 color: #737373;
}
.form-control {
 background-color: #fafafa !important;
 background-image: none;
border: 2px solid #eeeeee;
 box-shadow: none;
 color: #202b33;
 display: block;
 font-size: 14px;
 height: 35px;
 line-height: 1.4;
 padding: 5px 10px;
 transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
 width: 100%;
resize: vertical;
}
.form-control:focus {
 background-color: #ffffff;
 border-color: #d3e6f3;
 box-shadow: none;
 outline: 0 none;
}
.form-control.smallText { font-size: 14px; }

.form-control.hasError { border: 1px solid #e9573f !important; }

.inline-form { display: inline-block; }

label {
 display: inline-block;
 font-weight: 300;
 color: #202b33;
}

.custom-toast {
  background-color: red !important;
  color: white !important;
}

.custom-toast1 {
  background-color: brown !important;
  color: white !important;
}

.one{
  height:500px;
  width:500px;
}
.backbtn{
  color: orange;
}

